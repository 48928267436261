export function remap(value, low1, high1, low2, high2) {
	return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

export function setLocalStorageWithExpiry(key, value, ttl) {
	// TTL - miliseconds
	const now = new Date();
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry(key) {
	const itemStr = localStorage.getItem(key);

	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}

export function extractImgSrcFromString(str) {
	const regex = /<img[^>]*src="([^"]+)"[^>]*>/gm;
	let imgSrc = [];
	let m;
	while ((m = regex.exec(str)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === regex.lastIndex) {
			regex.lastIndex++;
		}

		// The result can be accessed through the `m`-variable.
		m.forEach((match, groupIndex) => {
			// console.log(`Found match, group ${groupIndex}: ${match}`);
			imgSrc.push(match);
		});
	}
	return imgSrc;
}
