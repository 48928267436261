import React from "react";
import { Modal, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { alertHide } from "../../redux/app/actions";
import { connect } from "react-redux";

const Alert = ({ alert, alertHide }) => {
	return (
		<Modal size={alert.size} open={alert.show}>
			<Modal.Header>{alert.title}</Modal.Header>
			<Modal.Content>
				<p>{alert.body}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button positive onClick={alertHide}>
					{alert.button}
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

Alert.propTypes = {
	alert: PropTypes.object,
};

Alert.defaultProps = {
	alert: {
		size: "mini",
		show: false,
		title: "",
		body: "",
		button: "Ok",
		onClose: () => {},
	},
};

const mapStateToProps = (state) => {
	return {
		alert: state.app.alert,
	};
};

const mapDispatchToProps = {
	alertHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
