import { useEffect, useState } from "react";

export const useHeaderScrolled = () => {
	const [windowScrolled, setWindowScrolled] = useState(0);
	const [isScrolled, setIsScrolled] = useState(false);

	const scrollPassHeader = (scrolled) => {
		const headerHeight = document.querySelector("header").clientHeight;
		// console.log("scroll header ", window.scrollY, headerHeight);
		if (scrolled >= headerHeight * 2) {
			if (!isScrolled) setIsScrolled(true);
		} else {
			if (isScrolled) setIsScrolled(false);
		}
	};

	useEffect(() => {
		const scrollHandler = () => {
			setWindowScrolled(window.scrollY);
			scrollPassHeader(window.scrollY);
		};

		scrollHandler();
		window.addEventListener("scroll", scrollHandler);

		return () => {
			window.removeEventListener("scroll", scrollHandler);
		};
	}, [isScrolled]);

	return { windowScrolled, isScrolled };
};
