import React from "react";
import { CustomButton } from "../../../assets/styles/globalCommon";
import { connect } from "react-redux";
import { appModalCloseAll, appRedirect } from "../../../redux/app/actions";

const PopupPremium = ({ appModalCloseAll, appRedirect }) => {
	const onClickHandler = () => {
		appModalCloseAll();
		appRedirect({ to: "/subscriptions" });
	};

	return (
		<div className="poupContentInner">
			<div className="popupBody">
				<h6>Conviértete en premium 🚀</h6>
				<h3>Disfruta de una experiencia digital en la nieve.</h3>

				<p>
					Crear alertas, mira hasta 14 días de pronóstico en 3 alturas,
					formate con nuestro curso de lectura de partes y disfruta de
					todos los beneficios de pertenecer a la comunidad de esquiadores.
				</p>
			</div>
			<div className="popupFooter">
				<CustomButton onClick={onClickHandler} className="">
					<p>Ver precios</p>
				</CustomButton>
				<p>
					Nuestros planes son los mas accesibles, además sin permanencia,
					podrás darte de baja cuando quieras.
				</p>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	appRedirect,
	appModalCloseAll,
};

export default connect(null, mapDispatchToProps)(PopupPremium);
