import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import l from "lodash";

const Container = styled.div`
	position: fixed;
	bottom: 20px;
	right: 25px;
	width: 250px;
	z-index: 1000;
`;

const Notification = styled.div`
	box-sizing: content-box;
	padding: 20px;
	margin-bottom: 20px;
	border: none;
	color: black;
	text-overflow: ellipsis;
	background: #01FFFC;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
`;

const Title = styled.div`
	font-weight: bold;
	margin-bottom: 5px;
	font-size: 18px;
`;

const Body = styled.div`
	font-size: 16px;
`;

const Notifications = ({ notifications }) => {
	return (
		notifications.length > 0 && (
			<Container>
				{l.reverse(notifications).map(({ id, title, body }) => {
					return (
						<Notification key={id}>
							{title && <Title>{title}</Title>}
							{body && <Body>{body}</Body>}
						</Notification>
					);
				})}
			</Container>
		)
	);
};

const mapStateToProps = (state) => {
	return {
		notifications: state.notifications.notifications,
	};
};

export default connect(mapStateToProps)(Notifications);
