import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	authLocalRegisterInit,
	authLocalRegisterSetData,
} from "../../../redux/auth/actions";

import {
	CustomCheckbox,
	InputBlock,
	StyledCustomFormSelect,
} from "../../../assets/styles/formCommon";
import { CustomButton } from "../../../assets/styles/globalCommon";
import {
	DropdownIndicator,
	generoOptions,
	deporteOptions,
	nivelOptions,
} from "../../../utils/forms";
import { ReactSVG } from "react-svg";
import { useIntl } from "gatsby-plugin-intl";
import l from "lodash";

const PopupRegister = ({
	registerData,
	registerErrors,
	paises,
	data,
	authLocalRegisterSetData,
	authLocalRegisterInit,
}) => {
	const intl = useIntl();
	const [showPassword, setShowPassword] = useState(false);
	const [paisesOptions, setPaisesOptions] = useState([]);

	console.log("registerErrors", registerErrors);

	const handleLocalRegister = (e) => {
		e.preventDefault();
		authLocalRegisterInit(intl.locale, registerData);
	};

	const handleFieldChange = (field, value) => {
		authLocalRegisterSetData(field, value);
	};

	useEffect(() => {
		if (data.email) {
			authLocalRegisterSetData("email", data.email);
		}
	}, [data.email]);

	useEffect(() => {
		const ps = [];
		if (paises) {
			paises.forEach((pais) => {
				ps.push({
					value: pais.id,
					label: pais.intl.title[intl.locale],
				});
			});
		}
		setPaisesOptions(ps);
	}, [paises]);

	return (
		<form onSubmit={handleLocalRegister}>
			<div className="poupContentInner">
				<div className="popupBody">
					<h3>Crear Cuenta</h3>

					{/* EMAIL */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-email") ||
							l.includes(registerErrors, "unavailable-email")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type="email"
									placeholder="Email"
									value={registerData.email}
									onChange={(e) =>
										handleFieldChange("email", e.target.value)
									}
								/>
							</div>
						</div>
						{l.includes(registerErrors, "unavailable-email") && (
							<div className="inputMsg">
								<ReactSVG
									src="/img/icons/icon-close.svg"
									wrapper="span"
									className="icon withPadding"
								/>
								<p>Dirección de E-mail no disponible</p>
							</div>
						)}
					</InputBlock>

					{/* NOMBRES */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-nombre")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type="text"
									placeholder="Nombres"
									value={registerData.nombre}
									onChange={(e) =>
										handleFieldChange("nombre", e.target.value)
									}
								/>
							</div>
						</div>
					</InputBlock>

					{/* APELLIDOS */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-apellido")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type="text"
									placeholder="Apellidos"
									value={registerData.apellido}
									onChange={(e) =>
										handleFieldChange("apellido", e.target.value)
									}
								/>
							</div>
						</div>
					</InputBlock>

					{/* GENERO */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-genero")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<StyledCustomFormSelect
									options={generoOptions}
									classNamePrefix="custom-form-select"
									components={{ DropdownIndicator }}
									isSearchable={false}
									placeholder="Género"
									onChange={(v) =>
										handleFieldChange("genero", v.value)
									}
									openMenuOnFocus={true}
								/>
							</div>
						</div>
					</InputBlock>

					{/* FECHA NACIMIENTO */}

					{/* <InputBlock
					className={`${
						l.includes(registerErrors, "missing-") ? "hasError" : ""
					}`}
				>
					<div className="inputWrapper">
						<div className="inputOut">
							<input
								type="date"
								max={new Date().toISOString().split("T")[0]}
							/>
						</div>
					</div>
				</InputBlock> */}

					{/* PAIS */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-pais")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<StyledCustomFormSelect
									options={paisesOptions}
									classNamePrefix="custom-form-select"
									components={{ DropdownIndicator }}
									isSearchable={false}
									onChange={(v) => handleFieldChange("pais", v.value)}
									openMenuOnFocus={true}
									placeholder="Selecciona tu país"
								/>
							</div>
						</div>
					</InputBlock>

					{/* PROVINCIA */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-provincia")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type="text"
									placeholder="Provincia"
									value={registerData.provincia}
									onChange={(e) =>
										handleFieldChange("provincia", e.target.value)
									}
								/>
							</div>
						</div>
					</InputBlock>

					{/* CIUDAD */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-ciudad")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type="text"
									placeholder="Ciudad"
									value={registerData.ciudad}
									onChange={(e) =>
										handleFieldChange("ciudad", e.target.value)
									}
								/>
							</div>
						</div>
					</InputBlock>

					{/* DEPORTE */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-deporte")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<StyledCustomFormSelect
									options={deporteOptions}
									classNamePrefix="custom-form-select"
									components={{ DropdownIndicator }}
									isSearchable={false}
									onChange={(v) =>
										handleFieldChange("deporte", v.value)
									}
									openMenuOnFocus={true}
									placeholder="Deporte"
								/>
							</div>
						</div>
					</InputBlock>

					{/* NIVEL */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-nivel")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<StyledCustomFormSelect
									options={nivelOptions}
									classNamePrefix="custom-form-select"
									components={{ DropdownIndicator }}
									isSearchable={false}
									onChange={(v) => handleFieldChange("nivel", v.value)}
									openMenuOnFocus={true}
									placeholder="Nivel"
								/>
							</div>
						</div>
					</InputBlock>

					{/* PASSWORD */}

					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-password") ||
							l.includes(registerErrors, "mismatched-passwords")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type={showPassword ? "text" : "password"}
									placeholder="Introduce tu contraseña o crea una nueva"
									value={registerData.password}
									onChange={(e) =>
										handleFieldChange("password", e.target.value)
									}
								/>
								<div className="inputAction">
									<CustomButton
										type="button"
										className="ghost withUnderline"
										onClick={(e) => {
											e.preventDefault();
											setShowPassword(!showPassword);
										}}
									>
										<p>{showPassword ? "Ocultar" : "Mostrar"}</p>
									</CustomButton>
								</div>
							</div>
						</div>
					</InputBlock>

					{/* PASSWORD 2 */}
					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-password2") ||
							l.includes(registerErrors, "mismatched-passwords")
								? "hasError"
								: ""
						}`}
					>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									type={showPassword ? "text" : "password"}
									placeholder="Repite tu contraseña"
									value={registerData.password2}
									onChange={(e) =>
										handleFieldChange("password2", e.target.value)
									}
								/>
								<div className="inputAction">
									<CustomButton
										type="button"
										className="ghost withUnderline"
										onClick={(e) => {
											e.preventDefault();
											setShowPassword(!showPassword);
										}}
									>
										<p>{showPassword ? "Ocultar" : "Mostrar"}</p>
									</CustomButton>
								</div>
							</div>
						</div>
						{l.includes(registerErrors, "mismatched-passwords") && (
							<div className="inputMsg">
								<ReactSVG
									src="/img/icons/icon-close.svg"
									wrapper="span"
									className="icon withPadding"
								/>
								<p>Las contraseñas no coinciden</p>
							</div>
						)}
					</InputBlock>

					{/* PRIVACIDAD */}
					<InputBlock
						className={`${
							l.includes(registerErrors, "missing-privacidad")
								? "hasError"
								: ""
						}`}
					>
						<CustomCheckbox layout="light">
							<label>
								<input
									type="checkbox"
									value="true"
									onChange={(e) =>
										handleFieldChange("privacidad", e.target.checked)
									}
								/>
								<span className="checkmark"></span>
								<p>Acepto las políticas de privacidad.</p>
							</label>
						</CustomCheckbox>
					</InputBlock>

					{/* NEWSLETTER */}
					<InputBlock marginBottom="0">
						<CustomCheckbox layout="light">
							<label>
								<input
									type="checkbox"
									value="true"
									onChange={(e) =>
										handleFieldChange("quiere_info", e.target.checked)
									}
								/>
								<span className="checkmark"></span>
								<p>
									No quiero recibir comunicación promocional a esta
									cuenta.
								</p>
							</label>
						</CustomCheckbox>
					</InputBlock>
				</div>
				<div className="popupFooter">
					<CustomButton>
						<p>Aceptar y Crear una cuenta</p>
					</CustomButton>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => {
	const { registerData, registerErrors } = state.auth;
	const { paises } = state.app.boot.data;
	return {
		registerData,
		registerErrors,
		paises,
	};
};

const mapDispatchToProps = {
	authLocalRegisterSetData,
	authLocalRegisterInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupRegister);
