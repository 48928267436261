import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import { Link, useIntl } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import {
	LocationSearchWrapper,
	LocationSearchOverlay,
	SuggestionsItem,
	SuggestionsWrapper,
} from "./styled";
import {
	centrosFiltersSet,
	centrosFiltersSetAndGet,
	centrosGet,
} from "../../../redux/centros/actions";
import {
	appRedirect,
	alertShow,
	appSetNoScroll,
} from "../../../redux/app/actions";
import {
	getGeo,
	changePlacesAutocomplete,
	selectPlacesAutocomplete,
	searchPlacesAutocomplete,
	resetFiltros,
} from "../../../utils/locations";
import { useLocation } from "@reach/router";
import ReactTooltip from "react-tooltip";
import { StyledTooltip } from "../../../assets/styles/pageCommon";
import { CSSTransition } from "react-transition-group";
import {
	getLocalStorageWithExpiry,
	setLocalStorageWithExpiry,
} from "../../../utils/utils";
import { graphql, useStaticQuery } from "gatsby";

const LocationSearch = (props) => {
	const {
		centrosFilters,
		centrosFiltersSet,
		centrosFiltersSetAndGet,
		centrosGet,
		appRedirect,
		alertShow,
		bootLoaded,
		isMobileResponsive,
		isMobile768,
		appSetNoScroll,
	} = props;

	const location = useLocation();
	const intl = useIntl();
	const inputSearchRef = useRef();
	const [showReset, setShowReset] = useState(
		centrosFilters.location.title !== "",
	);

	const [showToolTipOverlay, setShowToolTipOverlay] = useState(false);
	const tooltipRef = useRef();
	useEffect(() => {
		const instructionsValue = getLocalStorageWithExpiry("instructions");
		if (tooltipRef.current && bootLoaded) {
			if (instructionsValue === null) {
				setLocalStorageWithExpiry("instructions", true, 7776000000);
				ReactTooltip.show(tooltipRef.current);
			}
		}
	}, [bootLoaded]);

	const { edges: centros } = useStaticQuery(graphql`
		{
			allInternalCentros(
				filter: { alternative_id: { ne: null } }
				sort: { fields: title }
			) {
				edges {
					node {
						name
						title
					}
				}
			}
		}
	`).allInternalCentros;

	const [centrosEncontrados, setCentrosEncontrados] = useState([]);

	function searchSubstring(str, substring) {
		// Remove accents and special characters from the substring
		const normalizedSubstring = substring
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.replace(/[^a-zA-Z0-9\s]/g, "");

		// Check if the normalized substring has more than 3 characters
		if (normalizedSubstring.length < 4) {
			return false;
		}

		// Create a regular expression to match the normalized substring without accents and special characters
		const regex = new RegExp(
			normalizedSubstring
				.replace(/\s+/g, "\\s*")
				.replace(/[áàãâä]/gi, "[aáàãâä]")
				.replace(/[éèêë]/gi, "[eéèêë]")
				.replace(/[íìîï]/gi, "[iíìîï]")
				.replace(/[óòõôö]/gi, "[oóòõôö]")
				.replace(/[úùûü]/gi, "[uúùûü]"),
			"gi",
		);

		// Remove accents and special characters from the string and match against the regex
		return (
			str
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.replace(/[^a-zA-Z0-9\s]/g, "")
				.match(regex) !== null
		);
	}

	const typedSearchChange = (e) => {
		const typedSearch = e.value;
		const newCentrosEncontrados = [];
		centros.forEach((centro) => {
			if (searchSubstring(centro.node.title, typedSearch)) {
				newCentrosEncontrados.push({
					title: centro.node.title,
					name: centro.node.name,
				});
			}
		});
		setCentrosEncontrados(newCentrosEncontrados);
	};

	const handleGetGeo = () => {
		getGeo(
			centrosFilters,
			centrosFiltersSetAndGet,
			alertShow,
			appRedirect,
			intl,
		);
	};

	const handleChangePlacesAutocomplete = (value) => {
		setShowReset(value !== "");
		changePlacesAutocomplete(value, centrosFiltersSet);
	};

	const handleSelectPlacesAutocomplete = (address, placeId) => {
		if (placeId !== null) {
			selectPlacesAutocomplete(
				address,
				centrosFiltersSetAndGet,
				centrosFilters,
				appRedirect,
				intl,
			);
		}
	};

	const handleSearchPlacesAutocomplete = () => {
		searchPlacesAutocomplete(
			centrosFilters,
			centrosGet,
			alertShow,
			appRedirect,
			intl,
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleSearchPlacesAutocomplete();
	};

	const handleResetFiltros = () => {
		setShowReset(false);
		resetFiltros(centrosFiltersSet);
	};

	const handleOnInputStart = () => {
		if (isMobile768) {
			const scrolled = window.scrollY;
			// console.log("scrolled", scrolled);
			const offset = 10;
			// console.log("offset", offset);
			const headerHeight = document.querySelector("header").clientHeight;
			// console.log("headerHeight", headerHeight);
			const elementTop = Math.floor(
				inputSearchRef?.current?.getBoundingClientRect().top,
			);
			// console.log("elementTop", elementTop, typeof elementTop);

			window.scrollTo({
				top: elementTop + scrolled - headerHeight - offset,
			});
		}
		if (isMobileResponsive) {
			appSetNoScroll(true);
		}
	};

	const renderCentroItem = (centro, getSuggestionItemProps) => {
		let icon = "/img/icons/icon-skier.svg";
		let iconClass = "icon main-color";
		return (
			<SuggestionsItem
				{...getSuggestionItemProps(centro, {})}
				active={false}
				className="centroItem"
			>
				<ReactSVG src={icon} wrapper="span" className={iconClass} />
				<p>
					<Link to={`/resort/${centro.name}`}>{centro.title}</Link>
				</p>
			</SuggestionsItem>
		);
	};

	const renderSuggestionsItem = (suggestion, getSuggestionItemProps) => {
		let icon = "/img/icons/icon-location.svg";
		let iconClass = "icon";
		return (
			<SuggestionsItem
				{...getSuggestionItemProps(suggestion, {})}
				key={suggestion.placeId}
				active={suggestion.active}
				className="locationItem"
			>
				<ReactSVG src={icon} wrapper="span" className={iconClass} />
				<p>{suggestion.description}</p>
			</SuggestionsItem>
		);
	};

	return (
		<LocationSearchWrapper>
			{bootLoaded && (
				<>
					<StyledTooltip
						id="location-search"
						place="top"
						type="light"
						effect="solid"
						event="none"
						// scrollHide={false}
						// resizeHide={false}
						afterShow={() => setShowToolTipOverlay(true)}
						afterHide={() => setShowToolTipOverlay(false)}
						padding="24px 15px"
						maxWidth="215px"
					>
						<p>Haz clic para geolocalizarte o tipea una ciudad.</p>
					</StyledTooltip>
					<CSSTransition
						in={showToolTipOverlay}
						timeout={300}
						classNames="fadeAnim"
						unmountOnExit
					>
						<LocationSearchOverlay
							onClick={() => ReactTooltip.hide(tooltipRef.current)}
						/>
					</CSSTransition>
				</>
			)}
			<form onSubmit={handleSubmit}>
				<div className="inputWrapper">
					<div
						className="iconWrapper location"
						onClick={handleGetGeo}
						onKeyDown={null}
						role="button"
						tabIndex={0}
						aria-label="icon-location"
						ref={tooltipRef}
						data-tip=""
						data-for="location-search"
					>
						<ReactSVG
							src="/img/icons/icon-location.svg"
							wrapper="span"
							className="icon"
						/>
					</div>
					<PlacesAutocomplete
						onChange={handleChangePlacesAutocomplete}
						onSelect={handleSelectPlacesAutocomplete}
						value={centrosFilters.location.title}
						highlightFirstSuggestion={true}
					>
						{({
							getInputProps,
							suggestions,
							getSuggestionItemProps,
							loading,
						}) => {
							return (
								<>
									{/* el input */}
									<input
										ref={inputSearchRef}
										{...getInputProps({
											placeholder: "Elige tu ubicación",
											onKeyDown: () => {
												handleOnInputStart();
											},
											onKeyUp: () => {
												typedSearchChange(inputSearchRef.current);
											},
											onBlur: () => {
												appSetNoScroll(false);
											},
										})}
										// onChange={typedSearchChange}
										// value={typedSearch}
									/>
									{/* contenedor de resultados */}
									{suggestions && suggestions.length > 0 && (
										<>
											<LocationSearchOverlay />
											<SuggestionsWrapper
												outsideHeader={location.pathname === "/"}
											>
												{/* {loading && <div>Loading...</div>} */}
												{centrosEncontrados.map((ce) => {
													return renderCentroItem(
														ce,
														getSuggestionItemProps,
													);
												})}
												{/* {renderCentroItem(
													{
														id: 1,
														title: "Candanchu",
														name: "candanchu",
													},
													getSuggestionItemProps,
												)} */}
												{suggestions.map((suggestion) => {
													return renderSuggestionsItem(
														suggestion,
														getSuggestionItemProps,
													);
												})}
											</SuggestionsWrapper>
										</>
									)}
								</>
							);
						}}
					</PlacesAutocomplete>
					<div
						className={`iconWrapper reset ${showReset ? "show" : ""}`}
						onClick={handleResetFiltros}
						onKeyDown={null}
						role="button"
						tabIndex={0}
						aria-label="icon-reset"
					>
						<ReactSVG
							src="/img/icons/icon-close.svg"
							wrapper="span"
							className="icon"
						/>
					</div>
				</div>
			</form>
		</LocationSearchWrapper>
	);
};

const mapStateToProps = (state) => {
	const { isMobileResponsive, isMobile768 } = state.app.global;

	return {
		bootLoaded: state.app.boot.success,
		centrosFilters: state.centros.filters,
		isMobileResponsive,
		isMobile768,
	};
};

const mapDispatchToProps = {
	centrosFiltersSet,
	centrosFiltersSetAndGet,
	centrosGet,
	appRedirect,
	alertShow,
	appSetNoScroll,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearch);
