import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const CentroListItemWrapper = styled.div`
	background-color: ${colors.black16};
	border-bottom: 1px solid ${colors.black0A};
	//display: flex;
	border-left: 4px solid ${colors.black16};
	transition: border-left 0.3s ease-in-out;
	&:last-of-type {
		border-bottom: 0;
	}

	&:hover {
		border-left: 4px solid ${colors.main};
	}
	> a {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		padding: 10px 25px;
		@media ${mediaQueryMax.xl4} {
			padding: 10px;
			gap: 10px;
		}
		@media ${mediaQueryMax.lg2} {
			padding: 10px 25px;
			gap: 20px;
		}
		@media ${mediaQueryMax.lg} {
			padding: 10px;
			gap: 0;
		}
		@media ${mediaQueryMax.md} {
			padding: 10px 25px;
			gap: 20px;
		}
		@media ${mediaQueryMax.sm} {
			padding: 10px;
			gap: 10px;
		}
	}
`;

export const ListItemInfo = styled.div`
	flex: 0 0 50%;
	max-width: 50%;
	overflow: hidden;
	@media ${mediaQueryMax.xl4} {
		flex: 0 0 auto;
		max-width: 40%;
	}
	@media ${mediaQueryMax.md} {
		max-width: 50%;
	}
	@media ${mediaQueryMax.sm} {
		max-width: 40%;
	}
	.highlight {
		background: ${colors.main};
		font-weight: 500;
		color: ${colors.black00};
		font-size: 9px;
		line-height: 10px;
		text-align: center;
		text-transform: uppercase;
		padding: 3px 5px;
		border-radius: 5px;
	}
	p {
		font-size: 17px;
		color: ${colors.grayE7};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
		span {
			font-size: 15px;
			color: #959595;
		}
		@media ${mediaQueryMax.xl4} {
			font-size: 15px;
			span {
				font-size: 13px;
			}
		}
		@media ${mediaQueryMax.lg2} {
			font-size: 17px;
			span {
				font-size: 15px;
			}
		}
		@media ${mediaQueryMax.sm} {
			font-size: 15px;
			span {
				font-size: 13px;
			}
		}
	}
`;
export const ListItemWeather = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 14px;
	@media ${mediaQueryMax.xl4} {
		gap: 10px;
	}
	@media ${mediaQueryMax.lg2} {
		gap: 14px;
	}
	@media ${mediaQueryMax.sm} {
		gap: 10px;
	}
	.weather {
		.snow {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-around;
			gap: 4px;
			span.icon {
				width: 16px;
				@media ${mediaQueryMax.xl4} {
					width: 14px;
				}
				@media ${mediaQueryMax.lg2} {
					width: 16px;
				}
				@media ${mediaQueryMax.sm} {
					width: 14px;
				}
			}
			p {
				color: ${colors.main};
				@media ${mediaQueryMax.xl4} {
					font-size: 15px;
				}
				@media ${mediaQueryMax.lg2} {
					font-size: 17px;
				}
				@media ${mediaQueryMax.sm} {
					font-size: 15px;
				}
			}
		}
		p {
			text-align: center;
			span {
				font-size: 15px;
				color: #3e3e3e;
				@media ${mediaQueryMax.xl4} {
					font-size: 13px;
				}
				@media ${mediaQueryMax.lg2} {
					font-size: 15px;
				}
				@media ${mediaQueryMax.sm} {
					font-size: 13px;
				}
			}
		}
	}
`;
