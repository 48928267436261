import React from "react";
import { Accordion } from "semantic-ui-react";
import "../../assets/css/accordion.scss";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { SliderButton } from "@typeform/embed-react";
import { FooterWrapper, FooterContainer, FooterBlock } from "./styled";
import { ReactSVG } from "react-svg";
import {
	CustomLinkExternal,
	CustomButton,
	CustomLink,
} from "../../assets/styles/globalCommon";

const Index = (props) => {
	const { loggedIn } = props;

	return (
		<FooterWrapper>
			<FooterContainer>
				<FooterBlock className="newsletter">
					<div className="left">
						<SliderButton id="R9nLjQxI" size="75">
							<form>
								<input
									disabled={true}
									type="text"
									placeholder="Suscríbete a nuestro Newsletter"
									name="newsletter"
									id="newsletter"
								/>
								<ReactSVG
									src="/img/icons/icon-email.svg"
									wrapper="span"
									className="icon"
								/>
							</form>
						</SliderButton>
					</div>
					<div className="right">
						<p>Únete a nuestra comunidad</p>
						<CustomLinkExternal
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/freshsnow.pro/"
							className="secondary ghost onlyIcon"
						>
							<ReactSVG
								src="/img/icons/icon-ig.svg"
								wrapper="span"
								className="icon"
							/>
						</CustomLinkExternal>
						<CustomLinkExternal
							target="_blank"
							rel="noreferrer"
							href="https://discord.gg/sf8yKSbN"
							className="secondary ghost onlyIcon"
						>
							<ReactSVG
								src="/img/icons/icon-discord.svg"
								wrapper="span"
								className="icon"
							/>
						</CustomLinkExternal>
					</div>
				</FooterBlock>

				<FooterBlock className="logos">
					<div className="left">
						<p>Nuestros amigos</p>

						<ul>
							<li>
								<a
									href="https://www.lugaresdenieve.com/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/img/logos/logo-lugares-de-nieve.png"
										width="105"
										height="54"
										alt="Lugares de Nieve"
									/>
								</a>
							</li>
							<li>
								<a
									href="https://livingcrowdland.com/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/img/logos/logo-crowdland.png"
										width="180"
										height="21"
										alt="Living Crowdland"
									/>
								</a>
							</li>
							<li>
								<a
									href="https://shareyourboard.app/es/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src="/img/logos/logo-share-your-board.png"
										width="121"
										height="43"
										alt="Share your Borad"
									/>
								</a>
							</li>
						</ul>
					</div>
				</FooterBlock>

				<FooterBlock className="links">
					<div className="left">
						<img src="/img/logo-freshsnow.svg" alt="Fresh Snow" />
						<h3>
							Don´t wish <br />
							for the perfect snow day.
							<br /> Find it.
						</h3>
					</div>
					<div className="right">
						<div className="desktopLinks">
							<div className="links">
								<h6>Nuestro Servicio</h6>
								<ul>
									{loggedIn && (
										<>
											<li>
												<CustomLink
													to="/favs"
													className="ghost secondary"
												>
													<p>Favoritos</p>
												</CustomLink>
											</li>
											<li>
												<CustomLink
													to="/alerts"
													className="ghost secondary"
												>
													<p>Alertas</p>
												</CustomLink>
											</li>
										</>
									)}
									<li>
										<CustomLink
											to="/locations"
											className="ghost secondary"
										>
											<p>Descubre</p>
										</CustomLink>
									</li>
									<li>
										<CustomLink
											to="/resortsmap"
											className="ghost secondary"
										>
											<p>Mapa de Estaciones</p>
										</CustomLink>
									</li>
									<li>
										<CustomLink
											to="/subscriptions"
											className="ghost secondary"
										>
											<p>Precios</p>
										</CustomLink>
									</li>
								</ul>
							</div>
							<div className="links">
								<h6>Sobre Nosotros</h6>
								<ul>
									<li>
										<CustomLink
											to="/origins"
											className="ghost secondary"
										>
											<p>Origen</p>
										</CustomLink>
									</li>
									<li>
										<CustomLink
											to="/technology"
											className="ghost secondary"
										>
											<p>Nuestra Tecnología</p>
										</CustomLink>
									</li>
									<li>
										<CustomLink
											to="/snowgods"
											className="ghost secondary"
										>
											<p>Snowgods</p>
										</CustomLink>
									</li>
								</ul>
							</div>
							<div className="links">
								<h6>¿Necesitas Ayuda?</h6>
								<ul>
									{loggedIn && (
										<li>
											<CustomLink
												to="/account"
												className="ghost secondary"
											>
												<p>Mi Cuenta</p>
											</CustomLink>
										</li>
									)}
									<li>
										<CustomLink
											to="/help"
											className="ghost secondary"
										>
											<p>Ayuda & FAQs</p>
										</CustomLink>
									</li>
									<li>
										<SliderButton id="kr8xczVw">
											<CustomButton className="ghost secondary">
												<p>Contacto</p>
											</CustomButton>
										</SliderButton>
									</li>
								</ul>
							</div>
						</div>
						<div className="mobileLinks">
							<Accordion
								defaultActiveIndex={0}
								panels={[
									{
										key: 0,
										title: {
											content: "Nuestro servicio",
											icon: false,
										},
										content: {
											content: (
												<ul key={0}>
													{loggedIn && (
														<>
															<li>
																<CustomLink
																	to="/favs"
																	className="ghost secondary withUnderline"
																>
																	<p>Favoritos</p>
																</CustomLink>
															</li>
															<li>
																<CustomLink
																	to="/alerts"
																	className="ghost secondary withUnderline"
																>
																	<p>Alertas</p>
																</CustomLink>
															</li>
														</>
													)}
													<li>
														<CustomLink
															to="/locations"
															className="ghost secondary withUnderline"
														>
															<p>Descubre</p>
														</CustomLink>
													</li>
													<li>
														<CustomLink
															to="/resortsmap"
															className="ghost secondary withUnderline"
														>
															<p>Mapa de Estaciones</p>
														</CustomLink>
													</li>
													<li>
														<CustomLink
															to="/subscriptions"
															className="ghost secondary withUnderline"
														>
															<p>Precios</p>
														</CustomLink>
													</li>
												</ul>
											),
										},
									},
									{
										key: 1,
										title: {
											content: "Sobre Nosotros",
											icon: false,
										},
										content: {
											content: (
												<ul key={1}>
													<li>
														<CustomLink
															to="/origins"
															className="ghost secondary withUnderline"
														>
															<p>Origen</p>
														</CustomLink>
													</li>
													<li>
														<CustomLink
															to="/technology"
															className="ghost secondary withUnderline"
														>
															<p>Nuestra Tecnología</p>
														</CustomLink>
													</li>
													<li>
														<CustomLink
															to="/snowgods"
															className="ghost secondary withUnderline"
														>
															<p>Snowgods</p>
														</CustomLink>
													</li>
												</ul>
											),
										},
									},
									{
										key: 2,
										title: {
											content: "¿Necesitas Ayuda?",
											icon: false,
										},
										content: {
											content: (
												<ul key={2}>
													{loggedIn && (
														<li>
															<CustomLink
																to="/account"
																className="ghost secondary withUnderline"
															>
																<p>Mi Cuenta</p>
															</CustomLink>
														</li>
													)}
													<li>
														<CustomLink
															to="/help"
															className="ghost secondary withUnderline"
														>
															<p>Ayuda & FAQs</p>
														</CustomLink>
													</li>
													<li>
														<SliderButton id="kr8xczVw">
															<CustomButton className="ghost secondary withUnderline">
																<p>Contacto</p>
															</CustomButton>
														</SliderButton>
													</li>
												</ul>
											),
										},
									},
								]}
							/>
						</div>
					</div>
				</FooterBlock>

				<FooterBlock className="privacy">
					<div className="left">
						<ul>
							<li>
								<Link to="/avisolegal">
									<span>Aviso Legal</span>
								</Link>
							</li>
							<li>
								<Link to="/politica">
									<span>Política de Privacidad</span>
								</Link>
							</li>
							<li>
								<Link to="/cookies">
									<span>Cookies</span>
								</Link>
							</li>
							<li>
								<p>@ 2020 Fresh Snow</p>
							</li>
						</ul>
					</div>
				</FooterBlock>
			</FooterContainer>
		</FooterWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedIn: state.auth.loggedIn,
		locationOk:
			state.centros.filters.location.lat &&
			state.centros.filters.location.long,
	};
};

export default connect(mapStateToProps, null)(Index);
