import React from "react";
import { components } from "react-select";
import { ReactSVG } from "react-svg";

export const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ReactSVG
				src="/img/icons/icon-arrow-down.svg"
				wrapper="span"
				className="icon withPadding"
			/>
		</components.DropdownIndicator>
	);
};

export const generoOptions = [
	{ value: "M", label: "Masculino" },
	{ value: "F", label: "Femenino" },
	{ value: "O", label: "Otro" },
];

export const deporteOptions = [
	{ value: "snowboard", label: "Snowboard" },
	{ value: "ski", label: "Ski" },
];

export const nivelOptions = [
	{ value: "principiante", label: "Principiante" },
	{ value: "intermedio", label: "Intermedio" },
	{ value: "pro", label: "Pro" },
];
