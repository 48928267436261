import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { appModal } from "../../../redux/app/actions";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";
import { InputBlock } from "../../../assets/styles/formCommon";
import { CustomButton } from "../../../assets/styles/globalCommon";
import {
	authPasswordRecoveryInit,
	authPasswordRecoveryReset,
} from "../../../redux/auth/actions";

const PopupResetPass = ({
	appModal,
	authPasswordRecoveryInit,
	authPasswordRecoveryReset,
	data,
	passwordRecoveryMailSent,
}) => {
	const intl = useIntl();
	const [email, setEmail] = useState(data.email ? data.email : "");

	const handleSubmit = (e) => {
		e.preventDefault();
		authPasswordRecoveryInit(intl.locale, {
			email,
		});
	};

	useEffect(() => {
		// console.log("passwordRecoveryMailSent", passwordRecoveryMailSent);
		if (passwordRecoveryMailSent) {
			appModal("popupnewpass", { campaign: "newpass" });
			authPasswordRecoveryReset();
		}
	}, [passwordRecoveryMailSent]);

	return (
		<div className="poupContentInner">
			<div className="popupBody">
				<h6>Iniciar sesión o registrarse</h6>

				<h3>Recuperar Contraseña</h3>

				<form onSubmit={handleSubmit}>
					{/* EMAIL */}
					<InputBlock>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									name="email"
									type="email"
									placeholder="Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
					</InputBlock>

					<CustomButton type="submit" className="withIcon iconBig">
						<p>Enviar Link</p>
						<ReactSVG
							src="/img/icons/icon-lock.svg"
							wrapper="span"
							className="icon"
						/>
					</CustomButton>
				</form>
			</div>
			<div className="popupFooter">
				<CustomButton
					type="button"
					onClick={() => appModal("popuplogin", { campaign: "signup" })}
					className="secondary ghost withIcon"
				>
					<ReactSVG
						src="/img/icons/icon-arrow-left.svg"
						wrapper="span"
						className="icon"
					/>
					<p>Volver</p>
				</CustomButton>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		passwordRecoveryMailSent: state.auth.passwordRecoveryMailSent,
	};
};

const mapDispatchToProps = {
	appModal,
	authPasswordRecoveryInit,
	authPasswordRecoveryReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupResetPass);
