import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "./vars";
import { InputBlock } from "./formCommon";
import { ArrowsWrapper, CustomButton, CustomLink } from "./globalCommon";

export const PopupWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	left: 0;
	top: 0;
	z-index: 1100;
	@media ${mediaQueryMax.md} {
		//padding: 15px;
	}
`;

export const PopUpOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.85);
	z-index: 0;
`;

export const PopUpClose = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 0;
`;

export const PopupScrollWrapper = styled.div`
	height: 100%;
	box-sizing: border-box;
	padding: 30px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	z-index: 1;
	&::-webkit-scrollbar {
		width: 8px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 299, 181, 0.75);
	}
`;

const PupUpContentLayout = (layout) => {
	if (layout === "auto") {
		return css`
			max-width: 80vw;
		`;
	} else if (layout === "slider") {
		return css`
			max-width: 1060px;
		`;
	} else if (layout === "form") {
		return css`
			max-width: 550px;
		`;
	}
};

export const PopUpContent = styled.div`
	width: 100%;
	${(props) => PupUpContentLayout(props.layout)};
	position: relative;

	.popupClose {
		cursor: pointer;
		position: absolute;
		top: 18px;
		right: 18px;
		z-index: 10;
		@media ${mediaQueryMax.md} {
			top: -25px;
			right: 0;
		}
		span.icon {
			display: flex;
			width: 26px;
			height: 26px;
			@media ${mediaQueryMax.md} {
				width: 20px;
				height: 20px;
			}
			span {
				svg {
					path {
						fill: ${colors.black00};
						transition: fill 0.3s ease-in-out;
						@media ${mediaQueryMax.md} {
							fill: ${colors.white};
						}
					}
				}
			}
		}
		&:hover {
			svg {
				path {
					fill: ${colors.main};
				}
			}
		}
	}
	.poupContentInner {
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
	}
	form {
		${InputBlock} {
			padding: 0;
			margin-bottom: 25px;
			border: none;
			.inputWrapper {
				.inputOut {
					margin: 0;
				}
			}
		}
		${CustomButton} {
			width: 100%;
			&.ghost {
				text-align: left;
			}
		}
	}
	.popupBody {
		position: relative;
		background-color: ${colors.white};
		padding: 50px;
		@media ${mediaQueryMax.md} {
			padding: 25px;
		}
		@media ${mediaQueryMax.xs2} {
			padding: 15px;
		}
		h6 {
			font-weight: 500;
			margin: 0 0 25px;
		}
		h3 {
			margin: 0 0 25px;
		}
		.loadingWrapper {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
	}
	${ArrowsWrapper} {
		margin-top: 25px;
		justify-content: center;
	}
	.popupFooter {
		background-color: ${colors.black00};
		padding: 50px;
		@media ${mediaQueryMax.md} {
			padding: 25px;
		}
		@media ${mediaQueryMax.xs2} {
			padding: 15px;
		}
		${CustomButton}, ${CustomLink} {
			width: 100%;
			margin-bottom: 25px;
			&:last-of-type {
				margin-bottom: 0;
			}
			&.ghost {
				width: auto;
			}
			p {
				text-align: center;
				margin: 0;
			}
		}
		p {
			margin-top: 25px;
			color: ${colors.gray};
		}
	}

	.poupContentInner {
		&.sliderPopup {
			background-color: ${colors.white};
			.popupBody {
				background-color: transparent;
				padding-bottom: 0;
				.swiper {
					.swiper-wrapper {
						.swiper-slide {
							display: flex;
							flex-wrap: wrap;
							flex-direction: column;
							height: auto;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								flex: 1;
							}
							video {
								width: 100%;
								height: 100%;
								object-fit: cover;
								flex: 1;
							}
							iframe {
								width: 100%;
								max-width: 100%;
							}
							.slideFooter {
								height: 50px;
								padding: 0 2px;
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								justify-content: space-between;
								@media ${mediaQueryMax.md} {
									height: 30px;
								}
							}
						}
					}
				}
			}
		}
	}

	// POPUP WEBCAM
	&.popupwebcam {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		max-height: 720px;
		.poupContentInner {
			flex: 1;
			max-width: 100%;
			.popupBody {
				height: 100%;
				box-sizing: border-box;
				.swiper {
					height: 100%;
					.swiper-wrapper {
						height: 100%;
						.swiper-slide {
							height: 100%;
							img,
							video {
								object-fit: none;
								max-width: 100%;
								height: calc(100% - 50px);
								flex: 0 0 auto;
							}
							iframe {
								height: calc(100% - 50px);
								flex: 0 0 auto;
							}
						}
					}
				}
			}
		}
	}
`;
