import styled, { css } from "styled-components";
import { colors } from "./vars";
import { Link } from "gatsby";
import ReactSelect from "react-select";
import { SliderButton } from "@typeform/embed-react";

const CustomButtonStyles = css`
	background-color: ${colors.main};
	padding: 15px 20px;
	border-radius: 5px;
	overflow: hidden;
	display: inline-block;
	p {
		font-size: 16px;
		line-height: 18px;
		margin: 0;
		font-weight: 500;
		color: ${colors.black00} !important;
	}
	span.icon {
		width: 50px;
		height: 20px;
		display: block;
		span {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		svg {
			height: 100%;
			width: auto;
		}
	}
	// LAYOUTS
	&.secondary {
		background-color: ${colors.white};
		&.ghost {
			p {
				color: ${colors.white} !important;
			}
			span.icon {
				svg {
					path {
						fill: ${colors.white};
					}
				}
			}
		}
		&.outline {
			border: 1px solid ${colors.white};
			p {
				color: ${colors.white} !important;
			}
			span.icon {
				svg {
					path {
						fill: ${colors.white};
					}
				}
			}
		}
	}
	&.secondLevel {
		background-color: ${colors.gray40};
		p {
			color: ${colors.white} !important;
		}
		span.icon {
			svg {
				path {
					fill: ${colors.white};
				}
			}
		}
		&.ghost {
			p {
				color: ${colors.gray82} !important;
			}
			span.icon {
				svg {
					path {
						fill: ${colors.gray82};
					}
				}
			}
		}
	}
	&.alternative {
		background-color: ${colors.secondary};
		&.ghost {
			p {
				color: ${colors.main} !important;
			}
			span.icon {
				svg {
					path {
						fill: ${colors.main};
					}
				}
			}
		}
	}
	// TYPES
	&.withIcon {
		padding-right: 0;
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		&.iconBig {
			padding-top: 10px;
			padding-bottom: 10px;
			span.icon {
				height: 30px;
			}
		}
		p {
			text-align: center;
			flex: 1;
		}
	}
	&.onlyIcon {
		padding: 17px 0;
	}
	&.ghost {
		padding: 0;
		background-color: transparent;
		p {
			font-weight: 400;
		}
	}
	&.outline {
		background-color: transparent;
		border: 1px solid ${colors.black00};
	}
	&.withUnderline {
		p {
			text-decoration: underline;
		}
	}
	&.disabled {
		cursor: not-allowed !important;
		opacity: 0.65;
	}
`;

export const CustomButtonWrapper = styled.div`
	text-align: center;
	&.disabled {
		cursor: not-allowed;
	}
`;

export const StyledSliderButton = styled(SliderButton)`
	${CustomButtonStyles};
`;

export const CustomButton = styled.button`
	${CustomButtonStyles};
`;

export const CustomLink = styled(Link)`
	${CustomButtonStyles};
`;

export const CustomLinkExternal = styled.a`
	${CustomButtonStyles};
	cursor: pointer;
	&.disabled {
		pointer-events: none;
	}
`;

export const Breadcrumb = styled.div`
	margin-bottom: 25px;
	a {
		font-size: 16px;
		line-height: 1.125;
		color: ${colors.black00};
		text-decoration: underline;
		margin-left: 14px;
		&:hover {
			color: ${colors.black13};
			text-decoration: underline;
		}
		&:first-child {
			margin-left: 0;
		}
		&:not([href]) {
			color: ${colors.gray82};
			text-decoration: none;
		}
	}
	//a:not([href]):not([class]), a:not([href]):not([class]):hover
`;

export const StyledCustomSelect = styled(ReactSelect)`
	.custom-select__control {
		background-color: transparent;
		border-radius: 0;
		border: 1px solid ${colors.white};
		.custom-select__value-container {
			.custom-select__single-value {
				color: ${colors.white};
			}
			.custom-select__placeholder {
				margin: 0;
				color: ${colors.white};
			}
			input {
				padding: 0;
				margin: 0;
				height: auto;
			}
			.custom-select__input {
				input {
					display: none;
				}
			}
		}
		.custom-select__indicators {
			cursor: pointer;
			.custom-select__indicator-separator {
				display: none;
			}
			.custom-select__indicator {
				color: ${colors.white};
				svg {
				}
			}
		}
		&:hover {
			border-color: ${colors.grayF2};
		}
	}
	.custom-select__menu {
		margin: 0;
		border-radius: 0;
		.custom-select__menu-list {
			padding: 0;
			.custom-select__option {
				&.custom-select__option--is-focused {
					background-color: ${colors.ltblue3};
				}
				&.custom-select__option--is-selected {
					background-color: ${colors.ltblue};
				}
			}
		}
	}
`;

export const ArrowsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	${CustomButton} {
		overflow: hidden;
		border-radius: 5px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${colors.gray90};
		transition: background-color 0.3s ease-in-out;
		cursor: pointer;
		span.icon {
			width: 20px;
			svg {
				path {
					//fill: ${colors.white};
				}
			}
		}
		&:hover {
			background-color: ${colors.main};
		}
		&.aternative {
			background-color: ${colors.main};
			&:hover {
				background-color: ${colors.white};
			}
		}
	}
	&.alternative {
		${CustomButton} {
			background-color: ${colors.main};
			&:hover {
				background-color: ${colors.white};
			}
		}
	}
`;
