import actionTypes from "../actionTypes";

export const authLocalLoginInit = (lang, email, password) => {
	return {
		type: actionTypes.AUTH_LOCAL_LOGIN_INIT,
		payload: {
			lang,
			email,
			password,
		},
	};
};

export const authRecoveryLoginInit = (lang, key) => {
	return {
		type: actionTypes.AUTH_RECOVERY_LOGIN_INIT,
		payload: {
			lang,
			key,
		},
	};
};

export const authLocalLoginEmailCheckInit = (lang, email) => {
	return {
		type: actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_INIT,
		payload: {
			lang,
			email,
		},
	};
};

export const authSocialLoginInit = (lang, data) => {
	return {
		type: actionTypes.AUTH_SOCIAL_LOGIN_INIT,
		payload: {
			lang,
			data,
		},
	};
};

export const authLogout = (usuario) => {
	return {
		type: actionTypes.AUTH_LOGOUT,
		payload: usuario,
	};
};

export const authPerfilSaveInit = (lang, data) => {
	return {
		type: actionTypes.AUTH_PERFIL_SAVE_INIT,
		payload: {
			lang,
			data,
		},
	};
};

export const authLocalRegisterSetData = (field, value) => {
	return {
		type: actionTypes.AUTH_LOCAL_REGISTER_SET_DATA,
		payload: {
			field,
			value,
		},
	};
};

export const authLocalRegisterInit = (lang, data) => {
	return {
		type: actionTypes.AUTH_LOCAL_REGISTER_INIT,
		payload: {
			lang,
			data,
		},
	};
};

export const authPasswordRecoveryInit = (lang, data) => {
	return {
		type: actionTypes.AUTH_PASSWORD_RECOVERY_INIT,
		payload: {
			lang,
			data,
		},
	};
};

export const authPasswordRecoveryReset = () => {
	return {
		type: actionTypes.AUTH_PASSWORD_RECOVERY_RESET,
	};
};

export const authPasswordChangeInit = (lang, data) => {
	return {
		type: actionTypes.AUTH_PASSWORD_CHANGE_INIT,
		payload: {
			lang,
			data,
		},
	};
};
