import React from "react";
import ContentLoader from "react-content-loader";
import { colors } from "../../../assets/styles/vars";
import styled from "styled-components";

export const CentroListItemSkeletonWrapper = styled.div`
	background-color: ${colors.black16};
	border-left: 4px solid ${colors.black16};
	margin-bottom: 10px;
`;

const CentroListItemSkeleton = (props) => (
	<CentroListItemSkeletonWrapper>
		<ContentLoader
			speed={2}
			width="100%"
			height={79}
			// viewBox="0 0 426 79"
			backgroundColor={colors.black00}
			foregroundColor={colors.black13}
			{...props}
		>
			<rect x="6%" y="18" rx="0" ry="0" width="46%" height="20" />
			<rect x="6%" y="42" rx="0" ry="0" width="26%" height="18" />
			<rect x="56%" y="18" rx="0" ry="0" width="16%" height="20" />
			<rect x="56%" y="42" rx="0" ry="0" width="16%" height="18" />
			<rect x="77%" y="18" rx="0" ry="0" width="16%" height="20" />
			<rect x="77%" y="42" rx="0" ry="0" width="16%" height="18" />
		</ContentLoader>
	</CentroListItemSkeletonWrapper>
);

export default CentroListItemSkeleton;
