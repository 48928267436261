import React, { useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import "./styles.scss";
import Footer from "../Footer";
import {
	appBootGet,
	appRedirectReset,
	appSetIsMobile768,
	appSetIsMobileResponsive,
	appSetNoScroll,
	appSetCoupon,
} from "../../redux/app/actions";
import { connect } from "react-redux";
import { useIntl, navigate } from "gatsby-plugin-intl";
import Alert from "../Alert";
import { Modal } from "semantic-ui-react";
import Notifications from "../Notifications";
import ReactLoading from "react-loading";
import { GlobalStyle } from "../../assets/styles/globalStyles";
import { sizesValues } from "../../assets/styles/vars";
import Header from "../Header";
import { CSSTransition } from "react-transition-group";
import PopupContainer from "../Common/PopupContainer";
import { RemoveScroll } from "react-remove-scroll";
import qs from "query-string";
import { useLocation } from "@reach/router";

const Layout = ({
	children,
	appBootGet,
	appBootLoaded,
	appRedirect,
	appRedirectReset,
	appModal,
	isMobileResponsive,
	appSetIsMobileResponsive,
	isMobile768,
	appSetIsMobile768,
	bodyNoScroll,
	authResolved,
	loggedIn,
	usuario,
	appSetNoScroll,
	loading,
	isHome = false,
	showHeaderSearch,
	appSetCoupon,
	coupon,
}) => {
	const intl = useIntl();
	const location = useLocation();

	// app boot

	useEffect(() => {
		if (!appBootLoaded) appBootGet(intl.locale);
	}, [appBootLoaded]);

	// app redirect

	useEffect(() => {
		if (appRedirect.to !== null) {
			if (!appRedirect.external) {
				navigate(appRedirect.to);
				appRedirectReset();
			}
			if (appRedirect.external && authResolved && loggedIn) {
				const url = appRedirect.to
					.replace("<userEmail>", usuario.email)
					.replace("<userId>", usuario.id)
					.replace("<coupon>", coupon);
				appRedirectReset();
				window.location.href = url;
			}
		}
	}, [appRedirect, appRedirectReset, authResolved, loggedIn]);

	// cupon en la url?

	useEffect(() => {
		const parsed = qs.parse(location.search);
		if (parsed.coupon) {
			appSetCoupon(parsed.coupon);
		}
	}, []);

	// RESIZE
	// FIX PARA ALTURA MOBILE
	useEffect(() => {
		// FORZAR EL NO SCROLL
		appSetNoScroll(false);
		const resizeVhHandler = () => {
			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			const vh = window.innerHeight * 0.01;
			// console.log("vh resize", vh);
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty("--vh", `${vh}px`);

			// ANCHO SIN SCROLLBAR
			const scroller = document.scrollingElement;
			document.documentElement.style.setProperty(
				"--scrollbar-width",
				`${window.innerWidth - scroller.clientWidth}px`,
			);
		};

		resizeVhHandler();
		window.addEventListener("resize", resizeVhHandler);

		return () => {
			window.removeEventListener("resize", resizeVhHandler);
		};
	}, []);

	// IS MOBILE GLOBAL
	useEffect(() => {
		const resizeMobileHandler = () => {
			// console.log("window.innerWidth", window.innerWidth);
			if (window.innerWidth <= sizesValues.lg) {
				if (!isMobileResponsive) appSetIsMobileResponsive(true);
			} else {
				if (isMobileResponsive) appSetIsMobileResponsive(false);
			}

			if (window.innerWidth <= sizesValues.md) {
				if (!isMobile768) appSetIsMobile768(true);
			} else {
				if (isMobile768) appSetIsMobile768(false);
			}
		};

		resizeMobileHandler();
		window.addEventListener("resize", resizeMobileHandler);

		return () => {
			window.removeEventListener("resize", resizeMobileHandler);
		};
	}, [isMobileResponsive, isMobile768]);

	return (
		<RemoveScroll enabled={bodyNoScroll} removeScrollBar={true}>
			<GlobalStyle />
			<Header isHome={isHome} showSearch={showHeaderSearch} />
			<main className={isHome ? "home" : ""}>
				{children}
				<Alert />
			</main>
			<Notifications />
			<Footer />

			<CSSTransition
				in={!!appModal.name}
				timeout={300}
				classNames="fadeAnim"
				onEnter={() => appSetNoScroll(true)}
				onExited={() => appSetNoScroll(false)}
				unmountOnExit
			>
				<PopupContainer />
			</CSSTransition>

			<Modal basic open={loading}>
				<Modal.Content>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<ReactLoading
							type="spin"
							color="rgb(0, 255, 252)"
							height={"32px"}
							width={"32px"}
						/>
					</div>
				</Modal.Content>
			</Modal>
		</RemoveScroll>
	);
};

const mapStateToProps = (state) => {
	const { success: appBootLoaded } = state.app.boot;
	const { redirect: appRedirect, modal: appModal, coupon } = state.app;
	const { isMobileResponsive, isMobile768, bodyNoScroll } = state.app.global;
	const { loading } = state.app;
	const { resolved: authResolved, loggedIn, usuario } = state.auth;
	return {
		appBootLoaded,
		appRedirect,
		appModal,
		loading,
		isMobileResponsive,
		isMobile768,
		bodyNoScroll,
		authResolved,
		loggedIn,
		usuario,
		coupon,
	};
};

const mapDispatchToProps = {
	appBootGet,
	appRedirectReset,
	appSetIsMobileResponsive,
	appSetIsMobile768,
	appSetNoScroll,
	appSetCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
