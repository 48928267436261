import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactSVG } from "react-svg";
import {
	ArrowsWrapper,
	CustomButton,
	CustomLinkExternal,
} from "../../../assets/styles/globalCommon";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";

const PopupWebcams = ({ webcams }) => {
	const intl = useIntl();
	const [activeSlide, setActiveSlider] = useState(0);
	const sliderRef = useRef();

	const renderWebcambSlide = (item, itemTitle) => {
		if (item.imagen_url !== "") {
			return <img src={item.imagen_url} alt={itemTitle} />;
		} else if (item.video_url !== "") {
			return (
				<video width="960" height="540" muted autoPlay controls playsInline>
					<source src={item.video_url} type="application/x-mpegURL" />
					{/*<source src={item.url} type="video/mp4" />*/}
				</video>
			);
		} else if (item.iframe_url !== "") {
			return (
				<iframe
					width="960"
					height="540"
					title={itemTitle}
					src={item.iframe_url}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					style={{ border: "none" }}
					allowFullScreen
				/>
			);
		} else {
			return null;
		}
	};

	if (!webcams) return null;

	return (
		<>
			<div className="poupContentInner sliderPopup">
				<div className="popupBody">
					<div className="loadingWrapper">
						<ReactLoading
							type="spokes"
							color="rgb(0, 255, 252)"
							height={"32px"}
							width={"32px"}
						/>
					</div>
					<Swiper
						ref={sliderRef}
						slidesPerView={1}
						spaceBetween={0}
						onSlideChange={(swiper) =>
							setActiveSlider(swiper.activeIndex)
						}
					>
						{webcams?.map((item, index) => {
							const itemTitle = item.intl.title[intl.locale]
								? item.intl.title[intl.locale]
								: "Fuente no disponible";

							return (
								<SwiperSlide key={index}>
									{renderWebcambSlide(item, itemTitle)}
									<div className="slideFooter">
										<p>{itemTitle}</p>

										<CustomLinkExternal
											href={item.source_url ? item.source_url : "#"}
											target="_blank"
											rel="noreferrer"
											className="ghost"
										>
											<p>
												@{item.source ? item.source : "undefined"}
											</p>
										</CustomLinkExternal>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</div>

			<ArrowsWrapper className="alternative">
				<CustomButton
					disabled={activeSlide === 0}
					type="button"
					onClick={() => sliderRef?.current?.swiper.slidePrev()}
					className={`ghost onlyIcon ${activeSlide === 0 && "disabled"}`}
				>
					<ReactSVG
						src="/img/icons/icon-arrow-left.svg"
						wrapper="span"
						className="icon"
					/>
				</CustomButton>
				<CustomButton
					disabled={activeSlide === webcams.length - 1}
					type="button"
					onClick={() => sliderRef?.current?.swiper.slideNext()}
					className={`ghost onlyIcon ${
						activeSlide === webcams.length - 1 && "disabled"
					}`}
				>
					<ReactSVG
						src="/img/icons/icon-arrow-right.svg"
						wrapper="span"
						className="icon"
					/>
				</CustomButton>
			</ArrowsWrapper>
		</>
	);
};

const mapStateToProps = (state) => {
	const { webcams } = state.centro.data;
	return {
		webcams,
	};
};

export default connect(mapStateToProps, null)(PopupWebcams);
