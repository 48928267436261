import { createGlobalStyle } from "styled-components";
import { colors, mediaQueryMax } from "./vars";

export const GlobalStyle = createGlobalStyle`
	html{
    *::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }

    *::-webkit-scrollbar-track {
      background-color: rgba(249, 249, 249, 0.85);
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(80, 80, 80, 0.5);
    }
	}
  body {
    font-family: "Ubuntu", sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: ${colors.gray3C};
		overflow-x: hidden;
    scroll-behavior: smooth;
		&.no-scroll{
			overflow: hidden;
		}
    @media ${mediaQueryMax.lg}{
      font-size:15px;
    }
  }

  main {
		width: 100%;
    padding-top: 132px;
		@media ${mediaQueryMax.lg}{
			padding-top:70px;
			&.home{
				padding-top:0;
			}
		}
  }


  // TITLES
  h1,
  h2,
  h3,
  h4,
  h5,
	h6{
    font-family: "Ubuntu", sans-serif;
    line-height: 1.15;
    font-weight: 400;
    color: ${colors.black00};
    margin: 0;
  }
	
  h1 {
		font-weight: 500;
		font-size: 60px;
		letter-spacing: -2px;
    @media ${mediaQueryMax.xl}{
			font-size:52px;
    }
    @media ${mediaQueryMax.lg}{
			font-size:44px;
    }
    @media ${mediaQueryMax.sm} {
      font-size: 38px;
    }
  }

  h2 {
		font-size: 50px;
    @media ${mediaQueryMax.xl}{
      font-size:42px;
    }
    @media ${mediaQueryMax.lg}{
      font-size:36px;
    }
    @media ${mediaQueryMax.sm} {
      font-size: 30px;
    }
  }

  h3 {
    font-size: 33px;
    letter-spacing: -1px;
    @media ${mediaQueryMax.xl}{
      font-size:28px;
    }
    @media ${mediaQueryMax.lg}{
      font-size:24px;
    }
    @media ${mediaQueryMax.sm} {
      font-size: 20px;
    }
  }

  h4 {
		font-size: 25px;
    @media ${mediaQueryMax.xl}{
      font-size:22px;
    }
    @media ${mediaQueryMax.lg}{
      font-size:20px;
    }
    @media ${mediaQueryMax.sm} {
      //font-size: 18px;
    }
  }
	
	h5{
		font-size: 20px;
    @media ${mediaQueryMax.lg}{
      font-size:18px;
    }
    @media ${mediaQueryMax.sm} {
      //font-size: 16px;
    }
	}
	
	h6{
		font-size: 18px;
    @media ${mediaQueryMax.lg}{
      font-size:16px;
    }
    @media ${mediaQueryMax.sm} {
      //font-size: 14px;
    }
	}

  // TEXT
  p {
		font-weight: 300;
		&.medium{
			font-size: 15px;
		}
		&.small{
			font-size: 13px;
		}
  }

  // ICON
  span.icon{
    display: block;
    span{
      display: flex;
      svg{
        width: 100%;
        height: auto;
      }
    }
		&.withPadding{
			width: 50px;
			span{
				width: 20px;
				margin: auto;
			}
		}
  }

	// SCROLLBAR
  .rcs-custom-scroll .rcs-inner-handle {
    height: calc(100% - 12px);
    margin-top: 6px;
    background-color: rgba(0, 229, 181, 0.7);
    border-radius: 3px;
  }


  // FADE ANIMATION
  .fadeAnim-enter {
    opacity: 0;
		visibility: hidden;
  }
  .fadeAnim-enter-active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .fadeAnim-exit {
    opacity: 1;
    visibility: visible;
  }
  .fadeAnim-exit-active {
    opacity: 0;
		visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  // SLIDE ANIMATION
  .slideAnim-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  .slideAnim-enter-active {
    opacity: 1;
    max-height: 150px;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
  .slideAnim-exit {
    opacity: 1;
    max-height: 150px;
    overflow: hidden;
  }
  .slideAnim-exit-active {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }

  // SLIDE ANIMATION LONG
  .slideAnimLong-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  .slideAnimLong-enter-active {
    opacity: 1;
    max-height: 500px;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
  .slideAnimLong-exit {
    opacity: 1;
    max-height: 500px;
    overflow: hidden;
  }
  .slideAnimLong-exit-active {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
	
	//EMOJI FONT
  @font-face {
    font-family: "NotoColorEmoji";
    src: local("NotoColorEmoji"), local("NotoColorEmoji"),
    url("/fonts/NotoColorEmoji.ttf");
  }
	
`;
