import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { appModal } from "../../../redux/app/actions";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import {
	authSocialLoginInit,
	authLocalLoginInit,
	authLocalLoginEmailCheckInit,
} from "../../../redux/auth/actions";
import { InputBlock } from "../../../assets/styles/formCommon";
import { CustomButton } from "../../../assets/styles/globalCommon";
import l from "lodash";

const PopupLogin = ({
	appModal,
	authSocialLoginInit,
	authLocalLoginInit,
	authLocalLoginEmailCheckInit,
	localLoginEmailExists,
	loginErrors,
}) => {
	const intl = useIntl();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	/*const processFacebookResponse = (response) => {
		console.log("Facebook Response", response);
		const { first_name, last_name, email, signedRequest } = response;
		const pictureUrl = response.picture?.data?.url;
		authSocialLoginInit(intl.locale, {
			provider: "facebook",
			first_name,
			last_name,
			email,
			signedRequest,
			pictureUrl,
		});
	};*/

	const processGoogleSuccessResponse = (response) => {
		console.log("processGoogleSuccessResponse", response);
		const { tokenId } = response;
		authSocialLoginInit(intl.locale, {
			provider: "google",
			token: tokenId,
		});
	};

	const processGoogleErrorResponse = (error) => {
		console.log("processGoogleErrorResponse", error);
	};

	const doLocalLogin = (e) => {
		e.preventDefault();
		if (localLoginEmailExists)
			authLocalLoginInit(intl.locale, email, password);
		else authLocalLoginEmailCheckInit(intl.locale, email);
	};

	useEffect(() => {
		if (localLoginEmailExists === false) {
			appModal("popupregister", { email });
		}
	}, [localLoginEmailExists]);

	return (
		<div className="poupContentInner">
			<div className="popupBody">
				<h6>Iniciar sesión o registrarse</h6>

				<h3>Welcome!</h3>

				<form onSubmit={doLocalLogin}>
					{/* EMAIL */}
					<InputBlock>
						<div className="inputWrapper">
							<div className="inputOut">
								<input
									name="email"
									type="email"
									placeholder="Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
					</InputBlock>

					{/* PASSWORD */}
					{localLoginEmailExists === true && (
						<>
							<InputBlock
								className={`${
									l.includes(loginErrors, "wrong-login")
										? "hasError"
										: ""
								}`}
							>
								<div className="inputWrapper">
									<div className="inputOut">
										<input
											name="password"
											type="password"
											placeholder="Introduce tu contraseña"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</div>
								</div>
								{l.includes(loginErrors, "wrong-login") && (
									<div className="inputMsg">
										<ReactSVG
											src="/img/icons/icon-close.svg"
											wrapper="span"
											className="icon withPadding"
										/>
										<p>La contraseña es incorrecta.</p>
									</div>
								)}
							</InputBlock>

							<InputBlock>
								<p>
									Si creaste tu cuenta con Google o Facebook u
									olvidaste tu contraseña, puedes crear una nueva
									clicando en el siguiente link.
								</p>
							</InputBlock>

							<InputBlock>
								<CustomButton
									type="button"
									onClick={(e) => {
										e.preventDefault();
										appModal("popupresetpass", { email });
									}}
									className="ghost withUnderline"
								>
									<p>Recuperar o crear contraseña</p>
								</CustomButton>
							</InputBlock>
						</>
					)}

					<CustomButton type="submit" className="withIcon iconBig">
						<p>Continuar</p>
						<ReactSVG
							src="/img/icons/icon-email.svg"
							wrapper="span"
							className="icon"
						/>
					</CustomButton>
				</form>
			</div>
			<div className="popupFooter">
				{/*<CustomButton type="button" className="secondary outline withIcon">*/}
				{/*	<p>Continuar con Apple</p>*/}
				{/*	<ReactSVG*/}
				{/*		src="/img/icons/icon-apple.svg"*/}
				{/*		wrapper="span"*/}
				{/*		className="icon"*/}
				{/*	/>*/}
				{/*</CustomButton>*/}

				<GoogleLogin
					clientId="876208133827-2os8onvgb41nsbvt1fsv8atr1uj1eerf.apps.googleusercontent.com"
					buttonText="Login"
					onSuccess={processGoogleSuccessResponse}
					onFailure={processGoogleErrorResponse}
					cookiePolicy={"single_host_origin"}
					icon={false}
					render={({ onClick }) => (
						<CustomButton
							type="button"
							onClick={onClick}
							className="secondary outline withIcon"
						>
							<p>Continuar con Google</p>
							<ReactSVG
								src="/img/icons/icon-google.svg"
								wrapper="span"
								className="icon"
							/>
						</CustomButton>
					)}
				/>

				{/* <FacebookLogin
					// appId="1362844137170246" // production app
					appId="367388618440174" // development app
					callback={processFacebookResponse}
					render={(renderProps) => (
						<CustomButton
						type="button"
							onClick={renderProps.onClick}
							className="secondary outline withIcon"
						>
							<p>Continuar con Facebook</p>
							<ReactSVG
								src="/img/icons/icon-facebook.svg"
								wrapper="span"
								className="icon"
							/>
						</CustomButton>
					)}
					fields="first_name,middle_name,last_name,email,picture"
					scope="public_profile,email"
				/> */}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { loginErrors } = state.auth;
	return {
		localLoginEmailExists: state.auth.localLoginEmailExists,
		loginErrors,
	};
};

const mapDispatchToProps = {
	appModal,
	authSocialLoginInit,
	authLocalLoginInit,
	authLocalLoginEmailCheckInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupLogin);
