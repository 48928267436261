import styled from "styled-components";
import { CentroListItemWrapper } from "../CentroListItem/styled";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import { CustomLink } from "../../../assets/styles/globalCommon";
import { CentroListItemSkeletonWrapper } from "../CentroListItem/CentroListItemSkeleton";

export const ListWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 15px;
	${CentroListItemSkeletonWrapper} {
		margin-bottom: 0;
	}
	${CentroListItemSkeletonWrapper},
	${CentroListItemWrapper} {
		flex: 0 0 calc(50% - 12px);
		max-width: calc(50% - 12px);
		@media ${mediaQueryMax.md} {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
`;

export const EmptyList = styled.div`
	flex: 0 0 100%;
	max-width: 100%;
	padding: 75px 25px;
	box-sizing: border-box;
	//height: 258px;
	border: 1px dashed ${colors.main};
	border-radius: 10px;
	overflow: hidden;
	gap: 15px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	h3 {
		color: ${colors.main};
	}
	.text {
		color: ${colors.gray};
		max-width: 360px;
	}
`;

export const CentrosListWrapper = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 30px;
	position: relative;
	h4 {
		order: 0;
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00};
		margin-bottom: 0;
	}
	${CustomLink} {
		order: 1;
	}
	${ListWrapper} {
		order: 2;
	}
	@media ${mediaQueryMax.md} {
		${CustomLink} {
			order: 2;
		}
		${ListWrapper} {
			order: 1;
		}
	}
`;
