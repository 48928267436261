import actionTypes from "../actionTypes";

export const appBootGet = (lang) => {
	return {
		type: actionTypes.APP_BOOT_INIT,
		payload: lang,
	};
};

export const appRedirect = ({ to, options = {} }) => {
	return {
		type: actionTypes.APP_REDIRECT,
		payload: {
			to,
			options,
		},
	};
};

export const appRedirectReset = () => {
	return {
		type: actionTypes.APP_REDIRECT_RESET,
	};
};

export const appQueuedRedirect = ({ to, external, after }) => {
	return {
		type: actionTypes.APP_QUEUED_REDIRECT,
		payload: {
			to,
			external,
			after,
		},
	};
};

export const appQueuedRedirectReset = () => {
	return {
		type: actionTypes.APP_QUEUED_REDIRECT_RESET,
	};
};

export const alertShow = (data) => {
	return {
		type: actionTypes.ALERT_SHOW,
		payload: data,
	};
};

export const alertHide = () => {
	return {
		type: actionTypes.ALERT_HIDE,
	};
};

export const appConfig = (data) => {
	return {
		type: actionTypes.APP_CONFIG,
		payload: data,
	};
};

export const appModal = (name = null, data = null) => {
	return {
		type: actionTypes.APP_MODAL,
		payload: {
			name,
			data,
		},
	};
};

export const appModalCloseAll = () => {
	return {
		type: actionTypes.APP_MODAL_CLOSE_ALL,
	};
};

export const appModalClose = (name) => {
	return {
		type: actionTypes.APP_MODAL_CLOSE,
		payload: name,
	};
};

export const appSetIsMobileResponsive = (isMobileResponsive) => {
	return {
		type: actionTypes.APP_SET_IS_MOBILE_RESPONSIVE,
		payload: isMobileResponsive,
	};
};

export const appSetIsMobile768 = (isMobile768) => {
	return {
		type: actionTypes.APP_SET_IS_MOBILE_768,
		payload: isMobile768,
	};
};

export const appSetNoScroll = (bodyNoScroll) => {
	return {
		type: actionTypes.APP_SET_NO_SCROLL,
		payload: bodyNoScroll,
	};
};

export const appSetHeaderHide = (headerHide) => {
	return {
		type: actionTypes.APP_SET_HEADER_HIDE,
		payload: headerHide,
	};
};

export const appSetCoupon = (coupon) => {
	return {
		type: actionTypes.APP_SET_COUPON,
		payload: coupon,
	};
};
