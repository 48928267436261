import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";
import { CustomButton, CustomLink } from "../../assets/styles/globalCommon";

export const HeaderWrapper = styled.header`
	width: 100%;
	position: fixed;
	z-index: 500;
	left: 0;
	top: 0;
	transition: opacity 0.3s ease, transform 0.3s ease;
	${(props) =>
		props.isMobile &&
		props.bodyNoScroll &&
		css`
			position: absolute;
			top: ${props.windowScrolled}px;
		`}
	${(props) =>
		props.headerHide
			? css`
					transform: translateY(-100%);
					opacity: 0;
			  `
			: css`
					transform: translateY(0);
					opacity: 1;
			  `}
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 80px;
	gap: 0 24px;
	@media ${mediaQueryMax.xl} {
		padding: 0 50px;
	}
	@media ${mediaQueryMax.lg} {
		padding: 0 30px;
	}
	@media ${mediaQueryMax.md} {
		padding: 0 15px;
	}
`;

export const TopBar = styled.div`
	width: 100%;
	background-color: ${colors.white};
	padding: 10px 0;
	@media ${mediaQueryMax.lg} {
		display: none;
	}
	nav {
		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 0 20px;
			li {
				display: flex;
				a,
				button {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
`;

export const MenuBar = styled.div`
	width: 100%;
	height: 94px;
	background-color: ${colors.black00};
	transition: background-color 0.3s ease-in-out;
	padding: ${(props) => (props.showSearch ? "22px 0" : "30px 0")};
	@media ${mediaQueryMax.lg} {
		height: auto;
		padding: 15px 0;
		background-color: ${(props) =>
			props.isTransparent ? "transparent" : colors.black00};
	}
	a.logo {
		width: auto;
		height: 20px;
		@media ${mediaQueryMax.lg} {
			height: 40px;
		}

		span.icon-logo {
			//display: block;
			height: 100%;
			&.desktop {
				display: block;
				@media ${mediaQueryMax.lg} {
					display: none;
				}
			}
			&.mobile {
				display: none;
				@media ${mediaQueryMax.lg} {
					display: block;
				}
			}
			span {
				display: flex;
				height: 100%;
				svg {
					width: auto;
					height: 100%;
				}
			}
		}
	}
`;

export const SearchCol = styled.div`
	flex: 1;
	max-width: 500px;
`;

export const MenuCol = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0 24px;
	@media ${mediaQueryMax.xl} {
		> ${CustomButton}, > ${CustomLink} {
			display: none;
		}
	}
`;

export const UserInfo = styled.div`
	cursor: pointer;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0 18px;
	@media ${mediaQueryMax.lg} {
		display: none;
	}
	.profileImg {
		width: 32px;
		height: 32px;
		border: 2px solid ${colors.white};
		border-radius: 50%;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		span.icon {
			display: block;
			width: 100%;
			height: 100%;
			background-color: ${colors.gray};
			span {
				display: flex;
				svg {
					width: 100%;
					height: 100%;
					object-fit: cover;
					margin-top: 2px;
				}
			}
		}
	}
	.userName {
		h6 {
			color: ${colors.main};
			font-weight: 500;
			line-height: 20px;
		}
		p {
			color: ${colors.white};
			line-height: 14px;
		}
	}
`;

export const FloatingMenuWrapper = styled.div`
	position: absolute;
	top: calc(100% + 40px);
	min-width: 250px;
	right: -24px;
	background-color: ${colors.black00};
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
	overflow: hidden;
	.menuBox {
		padding: 25px;
		border-bottom: 1px solid ${colors.gray70};
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		&.alignTop {
			align-items: flex-start;
		}
		&.onlyMobile {
			display: none;
			@media ${mediaQueryMax.lg} {
				display: block;
			}
		}
		nav {
			@media ${mediaQueryMax.xs2} {
				order: 1;
			}
		}
		${UserInfo} {
			display: none;
			@media ${mediaQueryMax.md} {
				display: flex;
			}
			@media ${mediaQueryMax.xs2} {
				flex: 0 0 100%;
				max-width: 100%;
				justify-content: space-between;
				margin-bottom: 24px;
				gap: 18px;
				order: 0;
			}
		}
		&:last-of-type {
			border-bottom: none;
		}
		nav {
			ul {
				li {
					margin-top: 24px;
					&:first-of-type {
						margin-top: 0;
					}
					ul {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						gap: 0 24px;
						li {
							margin: 0;
						}
					}
				}
			}
		}
	}
	@media ${mediaQueryMax.lg} {
		right: -12px;
		max-height: 75vh;
		max-height: calc(var(--vh, 1vh) * 75);
		overflow-y: auto;
		top: calc(100% + 35px);
	}
	@media ${mediaQueryMax.md} {
		top: calc(100% + 25px);
		right: -15px;
		width: calc(100vw - var(--scrollbar-width));
		max-height: none;
		height: calc(100vh - 70px);
		height: calc((var(--vh, 1vh) * 100) - 70px);
		box-shadow: none;
		border-radius: 0;
	}
`;

export const MenuWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0 24px;
	position: relative;
	.menuBtn {
		cursor: pointer;
		span.icon {
			display: block;
			width: 20px;
			span {
				display: flex;
				svg {
					width: 100%;
					height: auto;
					path {
						fill: ${colors.white};
					}
				}
			}
		}
	}
`;
