import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "@reach/router";

export const useHeaderShowSearch = () => {
	const location = useLocation();
	const isMobile768 = useSelector((state) => {
		const { isMobile768 } = state.app.global;
		return isMobile768;
	});
	const [showSearch, setShowSearch] = useState(false);

	useEffect(() => {
		if (
			(location.pathname !== "/" && location.pathname !== "/resorts") ||
			(location.pathname === "/resorts" && isMobile768)
		) {
			setShowSearch(true);
		} else {
			setShowSearch(false);
		}
	}, [isMobile768]);

	return { showSearch };
};
