export const topBarMenu = [
	{
		text: "Origen",
		link: "/origins",
	},
	{
		text: "Nuestra Tecnología",
		link: "/technology",
	},
	{
		text: "Snowgods",
		link: "/snowgods",
	},
	{
		text: "Mapa de estaciones",
		link: "/resortsmap",
	},
	{
		text: "Descubre",
		link: "/locations",
	},
	{
		text: "Precios",
		link: "/subscriptions",
	},
	{
		text: "Cursos",
		link: "/courses",
	},
];

export const sortOptions = [
	{
		label: "Nieve últimas horas",
		value: "nieve",
	},
	{
		label: "Nieve próximas horas",
		value: "nieveprox",
	},
	{
		label: "Más cercanos",
		value: "distancia",
	},
	{
		label: "Nombre",
		value: "title",
	},
	{
		label: "Favoritos",
		value: "favoritos",
	},
];

export const protegemosDatosModule = [
	{
		moduleUrl: "",
		icon: "/img/icons/icon-lock.svg",
		title: "Protegemos tus datos",
		text: "Todos tus datos son seguros con nosotros. No los usaremos para nada que no nos hayas autorizado.",
		links: [
			{
				linkUrl: "/politica/",
				linkText: "Política de Privacidad",
			},
		],
	},
];

export const linksInteresModule = [
	{
		moduleUrl: "",
		icon: "/img/icons/icon-lock.svg",
		title: "Links de interés",
		links: [
			{
				linkUrl: "/account/",
				linkText: "Mi cuenta",
			},
			{
				linkUrl: "/resortsmap/",
				linkText: "Mapa de estaciones",
			},
			{
				linkUrl: "/",
				linkText: "Lugares",
			},
		],
	},
];

export const recibosData = [
	{
		firstCol: "Próximamente",
		secondCol: "",
		thirdCol: "",
		buttonUrl: "",
		buttonText: "",
	},
	// {
	// 	firstCol: "01/01/2022",
	// 	secondCol: "1464191272",
	// 	thirdCol: "12,00€",
	// 	buttonUrl: "/",
	// 	buttonText: "Descargar",
	// },
	// {
	// 	firstCol: "01/01/2022",
	// 	secondCol: "1464191272",
	// 	thirdCol: "12,00€",
	// 	buttonUrl: "/",
	// 	buttonText: "Descargar",
	// },
	// {
	// 	firstCol: "01/01/2022",
	// 	secondCol: "1464191272",
	// 	thirdCol: "12,00€",
	// 	buttonUrl: "/",
	// 	buttonText: "Descargar",
	// },
	// {
	// 	firstCol: "01/01/2022",
	// 	secondCol: "1464191272",
	// 	thirdCol: "12,00€",
	// 	buttonUrl: "/",
	// 	buttonText: "Descargar",
	// },
];

export const subscriptionModulesItems = [
	{
		icon: "/img/icons/icon-lock.svg",
		title: "14 dias de pronóstico",
		text: "Nadie te ofrece más, planifica y haz un seguimiento detallado del clima en la montaña.",
	},
	{
		icon: "/img/icons/icon-bell.svg",
		title: "Alertas ",
		text: "Configura alertas en tus estaciones favoritas y te avisaremos para que no te pierdas las mejores nevadas.",
	},
	{
		icon: "/img/icons/icon-heart.svg",
		title: "Favoritos",
		text: "Optimiza tus búsquedas creando un listado personalizado con tus estaciones favoritas.",
	},
	{
		icon: "/img/icons/icon-info.svg",
		title: "Info en 3 Alturas",
		text: "Ya sabes que la montaña ofrece diferentes condiciones según su altura. Afina tu decisión con la info completa en 3 alturas.",
	},
	{
		icon: "/img/icons/icon-plus.svg",
		title: "Reviews",
		text: "Publica y lee reviews de otros esquiadores y Snowgods. La información más valiosa compartida entre esquiadores.",
	},
	{
		icon: "/img/icons/icon-location.svg",
		title: "Geolocalización",
		text: "Te ordenamos las estaciónes con mejor nieve según tu posición, conocerás donde la montaña guarda sus mejores secretos.",
	},
	{
		icon: "/img/icons/icon-webcam.svg",
		title: "Cursos",
		text: "Convierte en un pro aprendiendo a leer partes para tomar las mejores decisiones.",
	},
	{
		icon: "/img/icons/icon-discord.svg",
		title: "Discord",
		text: "Únete a nuestra comunidad y comparte tu experiencia con otros esquiadores, organiza viajes y vende tu material en nuestro grupo de Discord.",
	},
	{
		icon: "/img/icons/icon-credit-card.svg",
		title: "Descuentos",
		text: "Por ser miembro de Fresh Snow, accederás a importantes descuentos, ¡no te los pierdas!",
	},
];

export const ayudaData = [
	{
		title: "¿Para que sirven las alertas?",
		text: "Nuestro sistema de alertas esta diseñado para que no te pierdas las mejores nevadas en tus estaciones favoritas. Solo tienes que clicar en la campanita 🔔 o bien en crear alerta y recibirás en tu email una notificación con el detalle de la próxima nevada cuando nuestro forecast la detecte. Las alertas se disparan solo con nevadas que acumulen más de 15cm en los siguientes 5 días.",
	},
	{
		title: "¿Cómo funcionan los favoritos?",
		text: "Al igual que las alertas, con solo clicar en el corazón ❤ o en crear favoritos, crearás un listado con tus estaciones preferidas. De este modo podrás verlas todas juntas (en la sección “Mis Favoritos”) sin tener que pasar por la búsqueda previa. Para quitar favoritos solo tienes que volver a clicar en el corazón ❤ o en quitar favorito.",
	},
	{
		title: "¿Cuántos días de pronóstico tengo con la cuenta gratis?",
		text: "Tendrás 5 días, con 3 alturas y gestión de favoritos. Además también podrás publicar partes.",
	},
	{
		title: "¿Cómo funciona la suscripción premium?",
		text: "Tienes dos tipos de planes con los mismos servicios, la diferencia es el modo de suscripción mensual o anual, en este último conseguirás un ahorro significativo.",
	},
	{
		title: "¿Puedo cancelar mi suscripción?",
		text: "¡Claro que sí! Podrás cancelar tu suscripción cuando tu quieras, no te pediremos permanencia ni intentaremos convercerte para que no lo hagas. Solo tienes que clicar en cancelar suscripción y en la siguiente renovación no se te cobrará ningún importe.",
	},
	{
		title: "Si cancelo mi suscripción, ¿Pierdo mi cuenta?",
		text: "No, tu cuenta camiará a <b>Starter</b> y podrás disfrutar de los beneficio de esa cuenta. Siempre podrás volver a ser <b>Premium</b> o <b>Smart</b> cuando tu quieras.",
	},
	{
		title: "¿Que son los cursos?",
		text: "Una de las grandes novedades que lanzamos este año, son los cursos de lectura de partes. Esto es una característica para los planes premium y smart. Allí, en 3 modulos, te enseñaremos como leer partes como un profesional, para que puedas tomar las mejores decisiones a la hora de seleccionar una estación, con el objetivo que puedas subir a la montaña en sus mejores días, y también ahorrando tiempo y dinero en tus viajes a la nieve.",
	},
	{
		title: "¿Qué es Discord?",
		text: "<b>Discord</b> es una plataforma social destinada a permitir crear grupos de chat para diferentes finalidades. Puedes pensar en ella como algo similar a Whatsapp, Telegram, aunque incluyendo las funciones de otras herramientas más profesionales, su mejor parecido es Slack. Allí podrás interactuar con otros esquiadores y hablar de partes de nieve, organizar viajes, vender material, etc.",
	},
	{
		title: "¿Qué es la geolocalización?",
		text: "Fresh Snow es el único forecast de nieve que ordena las estaciones en torno a la ubicación que tu elijas, además dispones de diferentes criterios: Cercanía, Nieve últimas horas, Nieve próximas horas, Nombre, Favoritos.",
	},
];

export const faqsData = [
	{
		title: "¿Puedo cancelar mi suscripción?",
		text: "¡Claro que sí! Podrás cancelar tu suscripción cuando tu quieras, no te pediremos permanencia ni intentaremos convercerte para que no lo hagas. Solo tienes que clicar en cancelar suscripción y en la siguiente renovación no se te cobrará ningún importe.",
	},
	{
		title: "Si cancelo mi suscripción, ¿Pierdo mi cuenta?",
		text: "No, tu cuenta camiará a <b>Starter</b> y podrás disfrutar de los beneficio de esa cuenta. Siempre podrás volver a ser <b>Premium</b> o <b>Smart</b> cuando tu quieras.",
	},
	{
		title: "¿Que diferencia hay entre Premium y Smart?",
		text: "Los beneficios son los mismos, solo cambia el precio. Smart es más económica y es una suscripción anual y Premium una suscripción mensual.",
	},
	{
		title: "¿Qué es Discord?",
		text: "<b>Discord</b> es una plataforma social destinada a permitir crear grupos de chat para diferentes finalidades. Puedes pensar en ella como algo similar a Whatsapp, Telegram, aunque incluyendo las funciones de otras herramientas más profesionales, su mejor parecido es Slack. Allí podrás interactuar con otros esquiadores y hablar de partes de nieve, organizar viajes, vender material, etc.",
	},
];

export const pricesColumns = [
	{
		name: "default",
		layout: "light",
		title: "Starter",
		priceType: "",
		price: "0€ <span>Gratis</span>",
		priceText: "Solo registro",
		features: [
			{
				text: "Días de pronóstico",
				qty: "5",
			},
			{
				text: "Webcams",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Favoritos",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Publicar Reviews",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Geolocalización",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Info en 3 alturas",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Acceso al Discord",
				icon: "/img/icons/icon-check.svg",
			},
			{
				status: "halfAccess",
				disabled: true,
				text: "1 Alerta",
				icon: "/img/icons/icon-check.svg",
			},
			{
				status: "disabled",
				disabled: true,
				text: "Curso online (lectura de partes)",
				icon: "/img/icons/icon-close.svg",
			},
			{
				status: "disabled",
				disabled: true,
				text: "Descuentos exclusivos",
				icon: "/img/icons/icon-close.svg",
			},
		],
		// buttonClass: "outline",
		// buttonText: "Plan Actual",
		// buttonUrl: "/",
		footerText:
			'<a href="/avisolegal" target="_blank" rel="noreferrer">Consulta los Términos y condiciones.</a>',
	},
	{
		name: "premium",
		title: "Premium",
		priceType: "Plan mensual <span>(5,00€)</span>",
		price: "1.99€ <span>Mes</span>",
		priceText: "Sin permanencia",
		features: [
			{
				text: "Días de pronóstico",
				qty: "14",
			},
			{
				text: "Webcams",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Favoritos",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Publicar Reviews",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Geolocalización",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Info en 3 alturas",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Acceso al Discord",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Alertas ilimitadas",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Curso online (lectura de partes)",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Descuentos exclusivos",
				icon: "/img/icons/icon-check.svg",
			},
		],
		// buttonText: "Suscribirme",
		// buttonUrl: "/",
		footerText:
			'<a href="/avisolegal" target="_blank" rel="noreferrer">Consulta los Términos y condiciones.</a>',
	},
	{
		name: "smart",
		layout: "dark",
		title: "Smart",
		priceType: "Plan anual <span>(24,00€)</span>",
		price: "9,99€ <span>Año</span>",
		priceText: "Prueba gratis 7 días.",
		features: [
			{
				text: "Días de pronóstico",
				qty: "14",
			},
			{
				text: "Webcams",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Favoritos",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Publicar Reviews",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Geolocalización",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Info en 3 alturas",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Acceso al Discord",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Alertas ilimitadas",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Curso online (lectura de partes)",
				icon: "/img/icons/icon-check.svg",
			},
			{
				text: "Descuentos exclusivos",
				icon: "/img/icons/icon-check.svg",
			},
		],
		// buttonText: "Suscribirme",
		// buttonUrl: "/",
		footerText:
			'<a href="/avisolegal" target="_blank" rel="noreferrer">Consulta los Términos y condiciones.</a>',
	},
];

export const snowGods = [
	{
		image: "/snowgods/ander-martinez.png",
		title: "Ander Martinez",
		link: { text: "Cantabria", url: "/snowgods" },
	},
	{
		image: "/snowgods/josep-deu-palacios.png",
		title: "Josep Deu Palacios",
		link: { text: "Cerdaña", url: "/snowgods" },
	},
	{
		image: "/snowgods/valentino-coton.png",
		title: "Valentino Coton",
		link: { text: "Andorra", url: "/snowgods" },
	},
	{
		image: "/snowgods/joan-cabrera.png",
		title: "Joan Cabrera",
		link: { text: "Vall d'Arán", url: "/snowgods" },
	},
	{
		image: "/snowgods/pamela-lustig.png",
		title: "Pamela Lustig",
		link: { text: "Pirineo Francés", url: "/snowgods" },
	},
	{
		image: "/snowgods/lenin-nunez-armas.png",
		title: "Lenin Nuñez",
		link: { text: "Aragón", url: "/snowgods" },
	},
	{
		image: "/snowgods/mariano-pelaez.png",
		title: "Mariano Peláez",
		link: { text: "Andorra", url: "/snowgods" },
	},
	{
		image: "/snowgods/jose-urbina.png",
		title: "Jose Urbina",
		link: { text: "Andorra", url: "/snowgods" },
	},
	{
		image: "/snowgods/pablo-saba.png",
		title: "Pablo Saba",
		link: { text: "Por del Comte", url: "/snowgods" },
	},
];

//https://unicode.org/emoji/charts/full-emoji-list.html
export const continentsData = [
	{
		name: "Europa",
		list: [
			{
				icon: "🇩🇪",
				name: "Alemania",
				qty: 17,
			},
			{
				icon: "🇪🇸",
				name: "España",
				qty: 13,
			},
			{
				icon: "🇦🇹",
				name: "Austria",
				qty: 8,
			},
			{
				icon: "🇫🇷",
				name: "Francia",
				qty: 10,
			},
			{
				icon: "🇨🇭 ",
				name: "Suiza",
				qty: 17,
			},
			{
				icon: "🇮🇹",
				name: "Italia",
				qty: 20,
			},
			{
				icon: "🇦🇩",
				name: "Andorra",
				qty: 17,
			},
		],
	},
	{
		name: "América del Sur",
		list: [
			{
				icon: "🇦🇷",
				name: "Argentina",
				qty: 8,
			},
			{
				icon: "🇨🇱",
				name: "Chile",
				qty: 8,
			},
		],
	},
];

export const countryData = [
	{
		name: "Pirineo Catalán",
	},
	{
		name: "Pirineo Aragonés",
	},
];
