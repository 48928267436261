import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const ModulesListWrapper = styled.section`
	width: 100%;
	position: relative;
	overflow: hidden;
	.listRow {
		display: flex;
		flex-wrap: wrap;
		margin-left: -12px;
		margin-right: -12px;
	}
`;

export const ListItem = styled.div`
	flex: 0 0 33.3333%;
	max-width: 33.3333%;
	padding: 0 12px;
	box-sizing: border-box;
	overflow: hidden;
	margin: 12px 0;
	@media ${mediaQueryMax.lg} {
		flex: 0 0 50%;
		max-width: 50%;
	}
	@media ${mediaQueryMax.sm} {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.inner {
		border-radius: 5px;
		background-color: ${colors.white};
		//padding: 50px;
		padding: 15%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: baseline;
		gap: 25px;
		height: 100%;
		position: relative;
		color: ${colors.black00};
		@media ${mediaQueryMax.lg} {
			padding: 10%;
		}
		span.list-icon {
			display: flex;
			width: 40px;
			span {
				display: flex;
				width: 100%;
			}
			svg {
				width: 100%;
				height: auto;
			}
			&.secondary {
				svg {
					path {
						fill: ${colors.secondary};
					}
				}
			}
			&.gray40 {
				svg {
					path {
						fill: ${colors.gray40};
					}
				}
			}
		}
		h4 {
			color: ${colors.black00} !important;
			margin: 0;
			span {
				font-size: 13px;
				font-weight: 300;
			}
		}
		p {
			color: ${colors.gray};
			margin: 0;
		}
		a {
			display: inline-block;
			font-size: 16px;
			color: ${colors.black00};
			text-decoration: underline;
		}
		button {
		}
	}
	.tooltipWrapper {
		position: absolute;
		top: 25px;
		right: 25px;
		z-index: 10;
		span.icon-tooltip {
			width: 30px;
			height: 30px;
			display: flex;
			> div {
				visibility: hidden;
				opacity: 0;
			}
			span {
				width: 100%;
				height: 100%;
				display: flex;
				svg {
					width: 100%;
					height: auto;
				}
			}
			&.red {
				svg {
					path {
						fill: ${colors.red};
					}
				}
			}
			&.green {
				svg {
					path {
						fill: ${colors.green};
					}
				}
			}
		}
	}
`;
