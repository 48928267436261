import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const MenuColSkeletonWrapper = styled.div`
	circle,
	rect {
		display: block;
		@media ${mediaQueryMax.lg} {
			display: none;
			&.alsoMobile {
				display: block;
			}
		}
	}
`;

const MenuColSkeleton = (props) => (
	<MenuColSkeletonWrapper>
		<ContentLoader
			speed={2}
			width={176}
			height={34}
			viewBox="0 0 176 34"
			backgroundColor={colors.gray80}
			foregroundColor={colors.black13}
			{...props}
		>
			<circle x="0" y="1" cx="16" cy="16" r="16" />

			<rect x="50" y="0" rx="0" ry="0" width="80" height="18" />
			<rect x="50" y="20" rx="0" ry="0" width="80" height="14" />

			<rect
				className="alsoMobile"
				x="162"
				y="7"
				width="12"
				height="2"
				rx="1"
			/>
			<rect
				className="alsoMobile"
				x="154"
				y="16"
				width="20"
				height="2"
				rx="1"
			/>
			<rect
				className="alsoMobile"
				x="154"
				y="25"
				width="20"
				height="2"
				rx="1"
			/>
		</ContentLoader>
	</MenuColSkeletonWrapper>
);

export default MenuColSkeleton;
