import styled, { css } from "styled-components";
import { colors } from "./vars";
import ReactSelect from "react-select";

export const StyledCustomFormSelect = styled(ReactSelect)`
	.custom-form-select__control {
		background-color: transparent;
		border: none;
		box-shadow: none;
		height: 60px;
		.custom-form-select__value-container {
			padding: 10px 15px;
			.custom-form-select__placeholder {
				margin: 0;
				color: ${colors.gray82};
			}
			input {
				padding: 0;
				margin: 0;
				height: auto;
			}
			.custom-form-select__input {
				input {
					display: none;
				}
			}
		}
		.custom-form-select__indicators {
			cursor: pointer;
			.custom-form-select__indicator-separator {
				background-color: ${colors.gray};
			}
			.custom-form-select__indicator {
				padding: 0;
				.icon {
					span {
						svg {
							path {
								fill: ${colors.black00};
							}
						}
					}
				}
			}
		}
		&:hover {
			border-color: ${colors.black00};
		}
		&.custom-form-select__control--is-focused {
			box-shadow: 0 0 5px rgba(0, 255, 252, 0.65);
		}
	}
	.custom-form-select__menu {
		margin: 0;
		border-radius: 0;
		.custom-form-select__menu-list {
			padding: 0;
			.custom-form-select__option {
				padding: 10px 15px;
				&.custom-form-select__option--is-focused {
					background-color: ${colors.ltblue3};
				}
				&.custom-form-select__option--is-selected {
					background-color: ${colors.ltblue};
				}
			}
		}
	}
`;

export const CustomCheckbox = styled.div`
	label {
		display: flex;
		flex-wrap: wrap;
		margin: 0;

		a {
			color: ${(props) =>
				props.layout === "light" ? colors.gray : colors.black00};
			margin-left: 0.5em;
		}

		p {
			flex: 1;
			margin: 0 !important;
			padding-right: 15px;
			font-size: 17px;
			color: ${(props) =>
				props.layout === "light" ? colors.gray : colors.black00};
		}

		[type="checkbox"] {
			margin: 0;
			padding: 0;
			box-shadow: none;
			width: 1px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		[type="checkbox"] + span {
			position: relative;
			margin: 0;
			cursor: pointer;
			width: 50px;
			display: block;

			&:before {
				content: "";
				border: 1px solid
					${(props) =>
						props.layout === "light" ? colors.gray : colors.black00};
				display: block;
				position: absolute;
				z-index: 0;
				left: 50%;
				transform: translateX(-50%);
				top: 2px;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				overflow: hidden;
			}

			&:after {
				content: "";
				background-color: ${(props) =>
					props.layout === "light" ? colors.gray : colors.black00};
				display: block;
				position: absolute;
				opacity: 0;
				z-index: 0;
				//left: 4px;
				left: 50%;
				transform: translateX(-50%);
				top: 6px;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				overflow: hidden;
			}
		}
		[type="checkbox"]:checked + span {
			&:after {
				opacity: 1;
			}
		}

		[type="checkbox"]:hover + span {
		}

		[type="checkbox"]:focus + span {
		}

		[type="checkbox"]:disabled + span {
		}
	}
`;

export const InputBlock = styled.div`
	${(props) =>
		props.marginBottom && `margin-bottom: ${props.marginBottom} !important`};
	${(props) =>
		!props.noLabel &&
		css`
			padding: 25px 0;
			border-bottom: 1px solid ${colors.grayE7};
		`};
	> p {
		font-size: 14px;
		color: ${colors.gray};
	}
	.inputInfoWrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.inputInfo {
			flex-grow: 1;
			font-weight: 400;
			font-size: 17px;
			line-height: 1.2;
			label {
				color: ${colors.black00};
				font: inherit;
				margin-bottom: 8px;
			}
			p {
				color: ${colors.gray82};
				font: inherit;
				margin: 0;
			}
		}
		.inputAction {
			padding: 0 20px;
			min-width: 120px;
			text-align: right;
		}
	}
	.inputWrapper {
		flex: 1;
		.inputOut {
			border: 1px solid ${colors.black00};
			border-radius: 5px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			${(props) =>
				!props.noLabel ? `margin: 25px 0;` : `margin: 0 0 15px;`};
		}
		input {
			flex: 1;
			color: ${colors.gray82};
			font: inherit;
			border-radius: 5px;
			height: 60px;
			padding: 10px 15px;
			background-color: transparent;
			&::placeholder {
				font: inherit;
			}
			&:focus {
				box-shadow: 0 0 5px rgba(0, 255, 252, 0.65);
			}
		}
		${StyledCustomFormSelect} {
			flex: 1;
		}
		.inputAction {
			width: 98px;
			box-sizing: border-box;
			padding: 10px 20px;
			text-align: center;
			position: relative;
			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 1px;
				height: 100%;
				background-color: ${colors.gray};
			}
		}
	}
	.inputMsg {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 15px;
		svg {
			path {
				fill: ${colors.gray82};
			}
		}
		p {
			color: ${colors.gray82};
			font-size: 13px;
		}
	}
	&.hasError {
		${CustomCheckbox} {
			label {
				.checkmark {
					&:before {
						border: 1px solid ${colors.red};
					}
				}
			}
		}
		.inputOut {
			border: 2px solid ${colors.red};
			.inputAction {
				&:before {
					background-color: ${colors.red};
				}
				button {
					p {
						color: ${colors.red} !important;
					}
				}
			}
			${StyledCustomFormSelect} {
				.custom-form-select__indicators {
					.custom-form-select__indicator-separator {
						background-color: ${colors.red};
					}
					.custom-form-select__indicator {
						.icon {
							span {
								svg {
									path {
										fill: ${colors.red};
									}
								}
							}
						}
					}
				}
			}
		}
		.inputMsg {
			svg {
				path {
					fill: ${colors.red};
				}
			}
		}

		p {
			color: ${colors.red};
		}
	}
`;
