import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const FooterWrapper = styled.footer`
	width: 100%;
	overflow: hidden;
	background-color: ${colors.black00};
	@media ${mediaQueryMax.md} {
		padding-bottom: 80px;
	}
`;

export const FooterContainer = styled.div`
	//max-width: 1340px;
	overflow: hidden;
	margin: 0 auto;
	padding: 0 80px;
	box-sizing: content-box;
	@media ${mediaQueryMax.xl} {
		padding: 0 50px;
	}
	@media ${mediaQueryMax.lg} {
		padding: 0 30px;
	}
	@media ${mediaQueryMax.md} {
		padding: 0 15px;
	}
`;

export const FooterBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid ${colors.gray70};
	border-bottom: 1px solid ${colors.gray70};
	padding: 40px 0;
	@media ${mediaQueryMax.lg} {
		padding: 30px 0;
		.left,
		.right {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	// NEWSLETTER
	&.newsletter {
		border-top: none;
		gap: 30px;
		@media ${mediaQueryMax.lg} {
			.left,
			.right {
				flex: auto;
				max-width: 100%;
			}
		}
		@media ${mediaQueryMax.md} {
			flex-direction: column;
			justify-content: center;
			.left,
			.right {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		.left {
			form {
				width: 475px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 20px;
				padding: 20px;
				border: 1px solid ${colors.gray70};
				border-radius: 5px;
				box-sizing: border-box;

				input {
					margin: 0;
					flex: 0 0 calc(100% - 50px);
					max-width: calc(100% - 50px);
					background: transparent;
					color: ${colors.white};

					&::placeholder {
						font-size: 17px;
						color: ${colors.white};
					}
				}

				span.icon {
					width: 30px;

					svg {
						path {
							fill: ${colors.white};
						}
					}
				}

				@media ${mediaQueryMax.lg} {
					width: 100%;
					max-width: 475px;

					input {
						&::placeholder {
							font-size: 15px;
						}
					}
				}
			}
		}
		.right {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;
			p {
				color: ${colors.white};
			}
			a {
				span.icon {
					width: auto;
					height: 36px;
					@media ${mediaQueryMax.lg} {
						height: 20px;
					}
				}
			}
		}
	}
	// NEWSLETTER

	// LOGOS
	&.logos {
		.left {
			p {
				font-size: 17px;
				line-height: 20px;
				color: ${colors.gray70};
				margin-bottom: 28px;
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 40px;
				margin: 0;
				padding: 0;
				li {
				}
			}
			@media ${mediaQueryMax.lg} {
				p {
					text-align: center;
				}
				ul {
					margin: auto;
					gap: 30px;
					justify-content: center;
				}
			}
		}
	}
	// LOGOS

	// LINKS
	&.links {
		align-items: flex-start;
		padding: 70px 0;
		@media ${mediaQueryMax.lg} {
			padding: 30px 0;
			//flex-wrap: wrap;
		}
		.left {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 30px;
			img {
			}

			h3 {
				margin: 0;
				color: ${colors.white};
			}
			@media ${mediaQueryMax.xl} {
				h3 {
					display: none;
				}
			}
			@media ${mediaQueryMax.lg} {
				flex: 0 0 100%;
				max-width: 100%;
				order: 2;
				flex-direction: row;
				//padding: 0 35px;
				img {
					width: 65px;
				}

				h3 {
					display: block;
				}
			}
			@media ${mediaQueryMax.lg} {
				//padding: 0 25px;
			}
		}
		.right {
			ul {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
				li {
					a,
					button {
						display: block;
					}
				}
			}
			.desktopLinks {
				display: flex;
				flex-wrap: wrap;
				gap: 60px;
				justify-content: flex-end;
				.links {
					h6 {
						font-weight: 500;
						color: ${colors.white};
						margin-bottom: 25px;
						text-transform: uppercase;
					}
				}
			}

			.mobileLinks {
				display: none;
				ul {
					gap: 12px;
				}
			}
			@media ${mediaQueryMax.lg} {
				flex: 0 0 100%;
				max-width: 100%;
				order: 1;
				border-bottom: 1px solid #3f3f3f;
				margin-bottom: 30px;

				.desktopLinks {
					display: none;
				}

				.mobileLinks {
					//padding: 0 35px;
					display: block;
				}
			}
			@media ${mediaQueryMax.md} {
				.mobileLinks {
					//padding: 0 25px;
				}
			}
		}
	}
	// LINKS

	// PRIVACY
	&.privacy {
		border-bottom: none;
		.left {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: flex-start;

			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 40px;
				padding: 0;
				margin: 0;
				li {
					a {
						font-size: 15px;
						color: ${colors.gray};
					}
					p {
						font-size: 15px;
						color: ${colors.gray};
					}
				}
			}
			@media ${mediaQueryMax.sm} {
				ul {
					margin: auto;
					gap: 20px;
					flex-direction: column;
				}
			}
		}
	}
	// PRIVACY
`;
