export const colors = {
	ltblue: "#00fffc", // usar main para unificar
	main: "#00FFFC",
	bluegreen: "#00E5B5", // usar secondary para unificar
	secondary: "#00E5B5",
	light20: "#F9F9F9",
	gray82: "#828282", // usar gray para unificar
	gray: "#828282",
	gray40: "#D5D5D5",
	gray70: "#505050",
	gray80: "#222222",
	gray90: "#101010",
	red: "#CE0000",
	green: "#00F076",

	black00: "#000000",
	black0A: "#0a0a0a",
	black10: "#101010",
	black13: "#131313",
	black16: "#161616",
	black17: "#171717",
	black18: "#181818",
	ltblue2: "#00ACD8",
	ltblue3: "#85FFFE",
	grayA3: "#a3a3a3",
	grayD8: "#d8d8d8",
	grayE7: "#e7e7e7",
	gray3C: "#3c3c3c",
	gray4C: "#464646",
	gray6C: "#6c6c6c",
	gray84: "#848484",
	gray7C: "#7c7c7c",
	grayF2: "#f2f2f2",
	white: "#FFFFFF",
	whiteE5: "#E5E5E5",
	whiteF4: "#f4f4f4",
};

// SIZES & QUERIES

export const sizesValues = {
	xs: 319,
	xs2: 414,
	sm: 576,
	md: 768,
	lg: 991,
	lg2: 1024,
	lg3: 1050,
	xl: 1200,
	xl2: 1280,
	xl3: 1366,
	xl4: 1400,
	xxl: 1500,
};

export const sizes = {
	xs: "319px",
	xs2: "414px",
	sm: "576px",
	md: "768px",
	lg: "991px",
	lg2: "1024px",
	lg3: "1050px",
	xl: "1200px",
	xl2: "1280px",
	xl3: "1366px",
	xl4: "1400px",
	xxl: "1500px",
};

export const mediaQueryMin = {
	xs: `(min-width: ${sizes.xs})`,
	xs2: `(min-width: ${sizes.xs2})`,
	sm: `(min-width: ${sizes.sm})`,
	md: `(min-width: ${sizes.md})`,
	lg: `(min-width: ${sizes.lg})`,
	lg2: `(min-width: ${sizes.lg2})`,
	lg3: `(min-width: ${sizes.lg3})`,
	xl: `(min-width: ${sizes.xl})`,
	xl2: `(min-width: ${sizes.xl2})`,
	xl3: `(min-width: ${sizes.xl3})`,
	xl4: `(min-width: ${sizes.xl4})`,
	xxl: `(min-width: ${sizes.xxl})`,
};

export const mediaQueryMax = {
	xs: `(max-width: ${sizes.xs})`,
	xs2: `(max-width: ${sizes.xs2})`,
	sm: `(max-width: ${sizes.sm})`,
	md: `(max-width: ${sizes.md})`,
	lg: `(max-width: ${sizes.lg})`,
	lg2: `(max-width: ${sizes.lg2})`,
	lg3: `(max-width: ${sizes.lg3})`,
	xl: `(max-width: ${sizes.xl})`,
	xl2: `(max-width: ${sizes.xl2})`,
	xl3: `(max-width: ${sizes.xl3})`,
	xl4: `(max-width: ${sizes.xl4})`,
	xxl: `(max-width: ${sizes.xxl})`,
};

// FONT WEIGHTS

export const weights = {
	light: 300,
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
};
