import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

export const getGeo = (
	centrosFilters,
	centrosFiltersSetAndGet,
	alertShow,
	appRedirect,
	intl,
) => {
	const geo = navigator.geolocation;
	if (geo) {
		geo.getCurrentPosition(
			(position) => {
				centrosFiltersSetAndGet(
					intl.locale,
					{
						location: {
							title: "Mi ubicación",
							lat: position.coords.latitude,
							long: position.coords.longitude,
						},
					},
					{
						lat: position.coords.latitude,
						long: position.coords.longitude,
						distancia: centrosFilters.distancia,
						sort: centrosFilters.sort,
						mode: "full",
						include_clima: 1,
						page: 1,
					},
				);
				appRedirect({ to: "/resorts" });
			},
			(error) => {
				alertShow({
					title: "¡Atención!",
					body: "No pudimos obtener tu ubicación.",
					button: "Ok",
				});
			},
		);
	}
};

export const changePlacesAutocomplete = (value, centrosFiltersSet) => {
	centrosFiltersSet({
		location: {
			title: value,
			lat: null,
			long: null,
		},
	});
};

export const selectPlacesAutocomplete = (
	address,
	centrosFiltersSetAndGet,
	centrosFilters,
	appRedirect,
	intl,
) => {
	geocodeByAddress(address).then((results) => {
		getLatLng(results[0]).then((latLng) => {
			centrosFiltersSetAndGet(
				intl.locale,
				{
					location: {
						title: address,
						lat: latLng.lat,
						long: latLng.lng,
					},
				},
				{
					lat: latLng.lat,
					long: latLng.lng,
					distancia: centrosFilters.distancia,
					sort: centrosFilters.sort,
					mode: "full",
					include_clima: 1,
					page: 1,
				},
			);
		});
		appRedirect({ to: "/resorts" });
	});
};

export const searchPlacesAutocomplete = (
	centrosFilters,
	centrosGet,
	alertShow,
	appRedirect,
	intl,
) => {
	if (!centrosFilters.location.lat || !centrosFilters.location.long) {
		alertShow({
			title: "Atención",
			body: "Debes ingresar tu ubicación para ver los resultados.",
			button: "Ok",
		});
	} else {
		centrosGet(intl.locale, {
			lat: centrosFilters.location.lat,
			long: centrosFilters.location.long,
			distancia: centrosFilters.distancia,
			sort: centrosFilters.sort,
			mode: "full",
			include_clima: 1,
			page: 1,
			token: localStorage.getItem("token"),
		});
		appRedirect({ to: "/resorts" });
	}
};

export const resetFiltros = (centrosFiltersSet) => {
	centrosFiltersSet({
		location: {
			title: "",
			lat: null,
			long: null,
		},
	});
};
