import styled, { css } from "styled-components";
import { sizes, colors, mediaQueryMin, mediaQueryMax } from "./vars";
import {
	ListItem,
	ModulesListWrapper,
} from "../../components/Common/ModulesList/styled";
import ReactTooltip from "react-tooltip";
import ReactSelect from "react-select";
import { CentrosListWrapper } from "../../components/Common/CentrosList/styled";

export const PageHero = styled.section`
	position: relative;
	min-height: 300px;
	${(props) =>
		props.bgImg
			? css`
					background-image: url(${props.bgImg});
					background-size: cover;
					background-position: 50% 50%;
					background-repeat: no-repeat;
			  `
			: css`
					background-color: ${colors.grayA3};
			  `};

	@media ${mediaQueryMin.md} {
		min-height: 350px;
	}
	@media ${mediaQueryMin.lg2} {
		min-height: 550px;
	}
`;

const textStyles = css`
	color: ${(props) =>
		props.layout === "light" ? colors.white : colors.black00};
	${(props) =>
		props.layout === "light" && `text-shadow:0 0 44px rgba(0, 0, 0, 0.5);`};
`;

export const PageHeroHeader = styled.div`
	width: 100%;
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	display: none;
	@media ${mediaQueryMin.lg2} {
		display: block;
	}
	> div {
		${textStyles};
		max-width: ${sizes.xl2};
		margin: 0 auto;
		padding: 25px 50px 0;
		box-sizing: content-box;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const PageHeroMap = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	@media ${mediaQueryMin.lg2} {
		//height: 770px;
	}
`;

export const PageHeroTitle = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	//flex-direction: column;
	height: 100%;
	width: 100%;
	z-index: 1;
	text-align: center;
	.content {
		padding: 0 25px;
		box-sizing: content-box;
	}
	.title {
		max-width: 960px;
		margin: 0 auto 20px;
	}
	.description {
		max-width: 560px;
		margin: 0 auto;
	}
	h1 {
		${textStyles};
		font-weight: 700;
		font-size: 100px;
		line-height: 0.85;
		margin-bottom: 0;
		@media ${mediaQueryMax.xl4} {
			font-size: 85px;
		}
		@media ${mediaQueryMax.xl} {
			font-size: 72px;
		}
		@media ${mediaQueryMax.lg} {
			font-size: 62px;
		}
		@media ${mediaQueryMax.sm} {
			font-size: 52px;
		}
		@media ${mediaQueryMax.xs2} {
			font-size: 44px;
		}
	}
	h3,
	h4 {
		${textStyles};
	}
	p {
		${textStyles};
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.5;
	}
`;

export const LineSeparator = styled.div`
	width: 100%;
	height: 1px;
	display: block;
	margin: 25px 0;
	background-color: ${(props) =>
		props.bgColor ? props.bgColor : colors.gray40};
`;

export const PageContent = styled.div`
	max-width: 100%;
	margin: 0 auto;
	max-width: ${(props) => (props.width ? props.width : "100%")};
	padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 50)}px;
	padding-bottom: ${(props) =>
		props.paddingBottom ? props.paddingBottom : 50}px;
	padding-left: 50px;
	padding-right: 50px;
	box-sizing: content-box;
	@media ${mediaQueryMax.lg} {
		padding-left: 35px;
		padding-right: 35px;
	}
	@media ${mediaQueryMax.md} {
		padding-left: 25px;
		padding-right: 25px;
	}
	h2,
	h3,
	h4 {
		margin-bottom: 30px;
	}
`;

export const PageContentHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 34px;
	h2,
	h3,
	h4 {
		margin-bottom: 0;
	}
`;

export const PageContentWrapper = styled.section`
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-bottom: 50px;
	${(props) =>
		props.bgColor && `background-color: ${props.bgColor} !important;`}
	background-color: ${(props) =>
		props.layout === "dark" ? colors.black00 : colors.white};
	${PageContent} {
		position: relative;
		.pageCol {
			color: ${(props) =>
				props.layout === "dark" ? colors.white : colors.gray};
			h2,
			h3,
			h4 {
				color: ${(props) =>
					props.layout === "dark" ? colors.white : colors.black00};
			}
			p {
				a {
					color: ${(props) =>
						props.layout === "dark" ? colors.white : colors.black00};
					text-decoration: underline;
				}
			}
		}
	}
	${PageContentHeader} {
		h2,
		h3,
		h4 {
			color: ${(props) =>
				props.layout === "dark" ? colors.white : colors.black00};
		}
	}
`;

export const PageTwoColWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.pageCol {
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 0;
		}
		&.fullCol {
			flex: 0 0 100%;
			max-width: 100%;
		}
		&.leftCol {
			flex: 0 0 63%;
			max-width: 63%;
		}
		&.rightCol {
			flex: 0 0 32%;
			max-width: 32%;
		}
		@media ${mediaQueryMax.lg} {
			&.leftCol {
				flex: 0 0 100%;
				max-width: 100%;
			}
			&.rightCol {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	.pageCol {
		// FULL COL
		&.fullCol {
			// SEARCH RESULTS
			div {
				> ul {
					> li {
						> ul {
							&:last-child {
								width: 50%;
							}
							li {
								&:last-child {
									width: auto;
									flex: 1;
								}
							}
						}
					}
				}
			}
		}

		// LEFT COL
		&.leftCol {
			form {
				margin-bottom: 60px;
			}
		}
		// RIGHT COL
		&.rightCol {
			${ModulesListWrapper} {
				${ListItem} {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}
`;

export const PageBlockWrapper = styled.div`
	position: relative;
	overflow: hidden;
	margin-bottom: 25px;
	${CentrosListWrapper} {
		margin-bottom: 50px;
	}
`;

export const PageBlockTitle = styled.div`
	padding-top: 25px;
	border-top: 1px solid ${colors.gray40};
	h3 {
		margin-bottom: 20px;
	}
`;

export const PageMessage = styled.div`
	margin-bottom: 24px;
	padding: 10px 0;
	border-top: 1px solid ${colors.gray40};
	border-bottom: 1px solid ${colors.gray40};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	@media ${mediaQueryMax.sm} {
		padding: 20px 0;
	}
	@media ${mediaQueryMax.sm} {
		flex-direction: column;
		align-items: flex-start;
		gap: 15px 0;
	}
	.PageMessageText {
		flex: 1;
		@media ${mediaQueryMax.sm} {
			width: 100%;
		}
		p {
			font-size: 17px;
			line-height: 1.7;
			&:first-of-type {
				color: ${colors.black00};
			}
		}
	}
	.PageMessageAction {
		margin-left: 10px;
		padding: 0 20px;
		@media ${mediaQueryMax.sm} {
			margin-left: 0;
			padding: 0;
		}
	}
`;

export const StyledTooltip = styled(ReactTooltip)`
	visibility: hidden;
	opacity: 0;
	background-color: ${colors.white} !important;
	padding: ${(props) => (props.padding ? props.padding : "15px")};
	color: ${colors.black18} !important;
	box-shadow: 0 0 17px rgba(0, 0, 0, 0.15);
	max-width: ${(props) => (props.maxWidth ? props.maxWidth : "none")};
	p {
		margin: 0;
		font-size: 15px !important;
		color: ${colors.black18} !important;
	}
	&.show {
		opacity: 1 !important;
	}
`;

export const StyledBgImage = styled.div`
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
	z-index: 0;
	overflow: hidden;
	${(props) =>
		props.bgImageDesktop &&
		`background: url(${props.bgImageDesktop}) center top no-repeat;`};
	background-size: cover;
	@media ${mediaQueryMax.md} {
		${(props) =>
			props.bgImageMobile &&
			`background: url(${props.bgImageMobile}) center bottom no-repeat`};
		background-size: cover;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		&.desktop {
		}
		&.mobile {
			display: none;
		}
	}
`;

export const StyledCustomSelect = styled(ReactSelect)`
	.custom-select__control {
		background-color: transparent;
		border: none;
		box-shadow: none;
		cursor: pointer;
		.custom-select__value-container {
			padding: 0;
			.custom-select__single-value {
				margin: 0;
				color: ${colors.main};
				text-decoration: underline;
			}
			.custom-select__placeholder {
				margin: 0;
				color: ${colors.main};
				text-decoration: underline;
			}
			input {
				padding: 0;
				margin: 0;
				height: auto;
			}
			.custom-select__input {
				input {
					display: none;
				}
			}
		}
		.custom-select__indicators {
			cursor: pointer;
			.custom-select__indicator-separator {
				background-color: transparent;
			}
			.custom-select__indicator {
				display: none;
				color: ${colors.main};
				padding: 0;
			}
		}
		&:hover {
			border-color: ${colors.black00};
		}
	}
	.custom-select__menu {
		margin: 0;
		border-radius: 0;
		.custom-select__menu-list {
			padding: 0;
			.custom-select__option {
				padding: 10px 15px;
				&.custom-select__option--is-focused {
					background-color: ${colors.ltblue3};
				}
				&.custom-select__option--is-selected {
					background-color: ${colors.ltblue};
				}
			}
		}
	}
`;
