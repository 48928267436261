import React from "react";
import { CustomButton } from "../../../assets/styles/globalCommon";
import { connect } from "react-redux";
import { appModalCloseAll, appRedirect } from "../../../redux/app/actions";

const PopupNewPass = ({ appModalCloseAll }) => {
	const onClickHandler = () => {
		appModalCloseAll();
	};

	return (
		<div className="poupContentInner">
			<div className="popupBody">
				<h6>Nueva contraseña 🔑</h6>
				<h3>Mira tu bandeja de entrada</h3>

				<p>Hemos un enviado un link a tu correo electrónico.</p>
				<p>
					Sigue las instrucciones para crear una nueva contraseña. Si
					tienes dudas o preguntas, puedes ponerte en contacto con nosotros.
				</p>
			</div>
			<div className="popupFooter">
				<CustomButton onClick={onClickHandler} className="">
					<p>Cerrar</p>
				</CustomButton>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	appRedirect,
	appModalCloseAll,
};

export default connect(null, mapDispatchToProps)(PopupNewPass);
