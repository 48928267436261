import React from "react";
import { ReactSVG } from "react-svg";

export const userInformationCompleted = (usuario) => {
	const userInfoToCheck = [
		"email",
		"nombre",
		"apellido",
		"genero",
		"fechaNac",
		"deporte",
		"nivel",
	];

	for (let i = 0; i < userInfoToCheck.length; i++) {
		// console.log("info ", userInfoToCheck[i], usuario[userInfoToCheck[i]]);
		if (
			usuario[userInfoToCheck[i]] === undefined ||
			usuario[userInfoToCheck[i]] === ""
		) {
			return false;
		}
	}
	return true;
};

export const getDeporteImgPerfil = (usuario) => {
	switch (usuario?.deporte) {
		case "Ski":
			return (
				<img
					src="/img/skier-profile-img.png"
					alt={`${usuario?.nombre} ${usuario?.apellido}`}
				/>
			);
		case "Snowboard":
			return (
				<img
					src="/img/snowboarder-profile-img.png"
					alt={`${usuario?.nombre} ${usuario?.apellido}`}
				/>
			);
		default:
			return (
				<ReactSVG
					src="/img/icons/icon-user.svg"
					wrapper="span"
					className="icon"
				/>
			);
	}
};

export const getDeportePerfil = (deporte) => {
	switch (deporte) {
		case "Ski":
			return "Skier";
		case "Snowboard":
			return "Snowboarder";
		default:
			return "";
	}
};
