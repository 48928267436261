import React from "react";
import { CustomButton, CustomLink } from "../../assets/styles/globalCommon";
import { FloatingMenuWrapper, UserInfo } from "./styled";
import { topBarMenu } from "../../data/global-data";
import { ReactSVG } from "react-svg";
import { SliderButton } from "@typeform/embed-react";
import { getDeporteImgPerfil, getDeportePerfil } from "../../utils/usuario";

const FloatingMenu = (props) => {
	const { isLoggedIn, usuario, openLogin, authLogout } = props;
	return (
		<FloatingMenuWrapper>
			{/* FIRST BOX */}
			<div className="menuBox alignTop">
				<nav>
					<ul>
						{!isLoggedIn ? (
							<>
								<li>
									<CustomButton
										onClick={openLogin}
										className="ghost alternative"
									>
										<p>Iniciar sesión</p>
									</CustomButton>
								</li>
								<li>
									<CustomButton
										onClick={openLogin}
										className="ghost alternative"
									>
										<p>Registrarse</p>
									</CustomButton>
								</li>
							</>
						) : (
							<>
								<li>
									<CustomLink to="/favs" className="ghost alternative">
										<p>Mis Favoritos</p>
									</CustomLink>
								</li>
								<li>
									<CustomLink
										to="/alerts"
										className="ghost alternative"
									>
										<p>Mis Alertas</p>
									</CustomLink>
								</li>
							</>
						)}
						<li>
							<CustomLink
								to="/subscriptions"
								className="ghost alternative"
							>
								<p>Precios</p>
							</CustomLink>
						</li>
					</ul>
				</nav>

				<UserInfo>
					{!isLoggedIn && (
						<CustomButton
							onClick={openLogin}
							className="ghost alternative"
						>
							<p>Regístrate gratis</p>
						</CustomButton>
					)}
					<div className="profileImg">
						{isLoggedIn ? (
							getDeporteImgPerfil(usuario)
						) : (
							<ReactSVG
								src="/img/icons/icon-user.svg"
								wrapper="span"
								className="icon"
							/>
						)}
					</div>
					{isLoggedIn && (
						<div className="userName">
							<h6>{usuario?.nombre}</h6>
							<p className="small">
								{getDeportePerfil(usuario?.deporte)}
							</p>
						</div>
					)}
				</UserInfo>
			</div>
			{/* FIRST BOX */}

			{/* SECOND BOX */}
			<div className="menuBox">
				<nav>
					<ul>
						{isLoggedIn && (
							<li>
								<CustomLink to="/account" className="ghost secondary">
									<p>Mi Cuenta</p>
								</CustomLink>
							</li>
						)}
						<li>
							<CustomLink to="/help" className="ghost secondary">
								<p>Ayuda</p>
							</CustomLink>
						</li>
						<li>
							<SliderButton id="kr8xczVw">
								<CustomButton className="ghost secondary">
									<p>Contacto</p>
								</CustomButton>
							</SliderButton>
						</li>
					</ul>
				</nav>
			</div>
			{/* SECOND BOX */}

			{/* THIRTH BOX */}
			<div className="menuBox onlyMobile">
				<nav>
					<ul>
						{topBarMenu
							?.filter((item) => item.text !== "Precios")
							.map((item, index) => (
								<li key={index}>
									<CustomLink
										to={item.link}
										className="ghost secondary"
									>
										<p>{item.text}</p>
									</CustomLink>
								</li>
							))}
					</ul>
				</nav>
			</div>
			{/* THIRTH BOX */}

			{/* FOURTH BOX */}
			{isLoggedIn && (
				<div className="menuBox">
					<nav>
						<ul>
							{/*{isMobileResponsive && (*/}
							{/*	<li>{renderLangMenu("secondLevel")}</li>*/}
							{/*)}*/}

							<li>
								<CustomButton
									onClick={authLogout}
									className="ghost secondary"
								>
									<p>Cerrar sesión</p>
								</CustomButton>
							</li>
						</ul>
					</nav>
				</div>
			)}
			{/* FOURTH BOX */}
		</FloatingMenuWrapper>
	);
};

export default FloatingMenu;
