import actionTypes from "../actionTypes";

export const centrosGet = (lang, data) => {
	return {
		type: actionTypes.CENTROS_LOAD_INIT,
		payload: { lang, data },
	};
};

export const centrosFiltersSet = (centrosFilters) => {
	return {
		type: actionTypes.CENTROS_FILTERS_SET,
		payload: centrosFilters,
	};
};

export const centrosFiltersSetAndGet = (lang, filters, data) => {
	return {
		type: actionTypes.CENTROS_FILTERS_SET_AND_LOAD_INIT,
		payload: { lang, filters, data },
	};
};
