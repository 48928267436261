import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { appModalCloseAll } from "../../../redux/app/actions";
import {
	PopupWrapper,
	PopUpOverlay,
	PopUpClose,
	PopupScrollWrapper,
	PopUpContent,
} from "../../../assets/styles/modalCommon";
import { ReactSVG } from "react-svg";
import PopupLogin from "../PopupContent/PopupLogin";
import PopupResetPass from "../PopupContent/PopupResetPass";
import PopupRegister from "../PopupContent/PopupRegister";
import PopupWebcams from "../PopupContent/PopupWebcams";
import PopupPremium from "../PopupContent/PopupPremium";
import PopupNewPass from "../PopupContent/PopupNewPass";

const PopupContainer = ({ currentAppModal, appModalCloseAll }) => {
	const [modalLayout, setModalLayout] = useState("normal");

	useEffect(() => {
		if (currentAppModal.name === "popupwebcam") {
			setModalLayout("slider");
		} else {
			setModalLayout("form");
		}
	}, [currentAppModal.name]);

	const renderPopupContent = () => {
		switch (currentAppModal.name) {
			case "popuplogin":
				return <PopupLogin data={currentAppModal.data} />;
			case "popupresetpass":
				return <PopupResetPass data={currentAppModal.data} />;
			case "popupnewpass":
				return <PopupNewPass />;
			case "popupregister":
				return <PopupRegister data={currentAppModal.data} />;
			case "popupwebcam":
				return <PopupWebcams data={currentAppModal.data} />;
			case "popuppremium":
				return <PopupPremium />;

			default:
				return null;
		}
	};

	return (
		<PopupWrapper>
			<PopUpOverlay />

			<PopupScrollWrapper>
				<PopUpClose
					onClick={appModalCloseAll}
					onKeyDown={null}
					role="button"
					tabIndex={0}
					aria-label="popup-close"
				/>
				<PopUpContent layout={modalLayout} className={currentAppModal.name}>
					<button onClick={appModalCloseAll} className="popupClose">
						<ReactSVG
							src="/img/icons/icon-close.svg"
							wrapper="span"
							className="icon"
						/>
					</button>

					{renderPopupContent()}
				</PopUpContent>
			</PopupScrollWrapper>
		</PopupWrapper>
	);
};

const mapStateToProps = (state) => {
	const { modal: currentAppModal } = state.app;
	return {
		currentAppModal,
	};
};

const mapDispatchToProps = {
	appModalCloseAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
