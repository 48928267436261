import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const LocationSearchWrapper = styled.div`
	form {
		width: 100%;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		.inputWrapper {
			position: relative;
			flex: 1;
			.iconWrapper {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				span.icon {
					width: 20px;
				}
				@media ${mediaQueryMax.lg} {
					span.icon {
						width: 16px;
					}
				}
				&.location {
					cursor: pointer;
					left: 15px;
					@media ${mediaQueryMax.lg} {
						left: 10px;
					}
					svg {
						path {
							fill: ${colors.secondary};
						}
					}
				}
				&.reset {
					right: 15px;
					opacity: 0;
					visibility: hidden;
					transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
					@media ${mediaQueryMax.lg} {
						right: 10px;
					}
					&.show {
						cursor: pointer;
						opacity: 1;
						visibility: visible;
					}
					svg {
						path {
							fill: ${colors.gray};
						}
					}
				}
			}
			input[type="text"] {
				width: 100%;
				background-color: ${colors.white};
				border-radius: 5px;
				overflow: hidden;
				font-size: 15px;
				line-height: 18px;
				padding: 16px 50px;
				@media ${mediaQueryMax.lg} {
					padding: 11px 36px;
				}
				@media ${mediaQueryMax.md} {
					border-radius: 5px;
				}
			}
		}
	}
`;

export const LocationSearchOverlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 900;
`;

export const SuggestionsWrapper = styled.div`
	position: absolute;
	z-index: 10000;
	left: 0;
	min-height: 200px;
	top: calc(100% + 10px);
	width: 100%;
	background-color: ${colors.white};
	padding: 25px 25px 25px 15px;
	box-sizing: border-box;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 2px 2px 4px rgba(130, 130, 130, 0.4);
	max-height: 75vh;
	overflow-y: auto;
	@media ${mediaQueryMax.md} {
		top: 66px;
		width: ${(props) =>
			props.outsideHeader ? "100%" : "calc(100vw - 30px)"};
		right: ${(props) => (props.outsideHeader ? "0" : "-44px")};
		left: auto;
	}
`;

export const SuggestionsItem = styled.div`
	margin-top: 20px;
	cursor: pointer;
	word-break: break-word;
	//color: ${(props) => (props.active ? colors.secondary : colors.black00)};
	transition: color 0.3s ease-in-out;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 15px;
	color: ${colors.black00};
	&:hover {
		// color: ${colors.secondary};
	}
	&:first-of-type {
		margin-top: 0;
	}
	&:last-of-type {
	}

	span.icon {
		width: 32px;
		height: 32px;
		background-color: ${colors.black00};
		border-radius: 5px;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		svg {
			max-width: 22px;
			max-height: 22px;
			//border-radius: 50%;
			overflow: hidden;
			path {
				fill: ${colors.white};
			}
		}
		&.main-color {
			svg {
				path {
					fill: ${colors.main};
				}
			}
		}
	}
	p {
		margin: 0;
		flex: 1;
		font-size: 17px;
		font-weight: 400;
		line-height: 1.15;
		a {
			font: inherit;
			color: inherit;
		}
	}
	&.centroItem {
		color: ${colors.secondary};
		&:hover {
			color: ${colors.black00};
		}
	}
`;
