import React, { useState, useEffect } from "react";
import {
	HeaderContainer,
	HeaderWrapper,
	MenuBar,
	MenuCol,
	MenuWrapper,
	SearchCol,
	TopBar,
	UserInfo,
} from "./styled";
import { topBarMenu } from "../../data/global-data";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import {
	appModal,
	appRedirect,
	appSetNoScroll,
	appSetHeaderHide,
} from "../../redux/app/actions";
import { authLogout } from "../../redux/auth/actions";
import { connect } from "react-redux";
import { CustomButton, CustomLink } from "../../assets/styles/globalCommon";
import FloatingMenu from "./FloatingMenu";
import { CSSTransition } from "react-transition-group";
import LocationSearch from "../Common/LocationSearch";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { getDeporteImgPerfil, getDeportePerfil } from "../../utils/usuario";
import MenuColSkeleton from "./UserSkeleton";
import { useHeaderScrolled } from "../../hooks/useHeaderScrolled";
import { useHeaderShowSearch } from "../../hooks/useHeaderShowSearch";
import { useScrollDirection } from "../../hooks/useScrollDirection";

const Header = (props) => {
	const {
		authResolved,
		loggedIn,
		usuario,
		authLogout,
		appModal,
		isMobileResponsive,
		isHome,
		bodyNoScroll,
		appSetNoScroll,
		headerHide,
		appSetHeaderHide,
	} = props;

	const { isMobile } = useDeviceDetect();
	const [isMenuOpened, setIsMenuOpened] = useState(false);

	const scrollDirection = useScrollDirection();
	const { windowScrolled, isScrolled } = useHeaderScrolled();
	const { showSearch } = useHeaderShowSearch();

	useEffect(() => {
		if (!isMobileResponsive && isMenuOpened) appSetNoScroll(false);
		if (isMobileResponsive && isMenuOpened) appSetNoScroll(true);
	}, [isMobileResponsive, isMenuOpened]);

	useEffect(() => {
		if (!isMobile) {
			if (isScrolled && scrollDirection === "down") {
				appSetHeaderHide(true);
			} else if (isScrolled && scrollDirection === "up") {
				appSetHeaderHide(false);
			} else {
				appSetHeaderHide(false);
			}
		}
	}, [isMobile, isScrolled, scrollDirection]);

	const openLogin = () => {
		appModal("popuplogin", { campaign: "signup" });
		setIsMenuOpened(false);
	};

	const menuHandler = () => {
		setIsMenuOpened(!isMenuOpened);
		if (isMobileResponsive) {
			appSetNoScroll(!bodyNoScroll);
		}
	};

	const renderLangMenu = (layout) => {
		return (
			<ul>
				<li>
					<CustomButton
						onClick={() => alert("es")}
						className={`ghost ${layout}`}
					>
						<p>ES</p>
					</CustomButton>
				</li>
				<li>
					<CustomButton
						onClick={() => alert("en")}
						className={`ghost ${layout}`}
					>
						<p>EN</p>
					</CustomButton>
				</li>
			</ul>
		);
	};

	const renderPremiumText = () => {
		if (!isMobileResponsive) {
			if (!loggedIn) {
				return (
					<CustomButton onClick={openLogin} className="ghost alternative">
						<p>Regístrate gratis</p>
					</CustomButton>
				);
			} else if (loggedIn && !usuario?.premium) {
				return (
					<CustomLink to="/subscriptions" className="ghost alternative">
						<p>Conviérte en premium</p>
					</CustomLink>
				);
			}
		}
	};

	return (
		<HeaderWrapper
			isMobile={isMobile}
			bodyNoScroll={bodyNoScroll}
			windowScrolled={windowScrolled}
			headerHide={headerHide}
		>
			<TopBar>
				<HeaderContainer>
					<nav className="left">
						<ul>
							{topBarMenu?.map((item, index) => (
								<li key={index}>
									<CustomLink to={item.link} className="ghost">
										<p>{item.text}</p>
									</CustomLink>
								</li>
							))}
						</ul>
					</nav>
				</HeaderContainer>
			</TopBar>

			<MenuBar
				isHome={isHome}
				isTransparent={isHome && !isScrolled && !isMenuOpened}
				showSearch={showSearch}
			>
				<HeaderContainer>
					<Link to="/" className="logo">
						<>
							<ReactSVG
								src="/img/logo-freshsnow-oneline.svg"
								wrapper="span"
								className="icon-logo desktop"
							/>
							<ReactSVG
								src="/img/logo-freshsnow.svg"
								wrapper="span"
								className="icon-logo mobile"
							/>
						</>
					</Link>

					{showSearch && (
						<SearchCol>
							<LocationSearch />
						</SearchCol>
					)}

					{authResolved ? (
						<MenuCol>
							{renderPremiumText()}

							<MenuWrapper>
								<UserInfo onClick={menuHandler}>
									<div className="profileImg">
										{getDeporteImgPerfil(usuario)}
									</div>
									{loggedIn && (
										<div className="userName">
											<h6>{usuario?.nombre}</h6>
											<p className="small">
												{getDeportePerfil(usuario?.deporte)}
											</p>
										</div>
									)}
								</UserInfo>

								<div className="menuBtn" onClick={menuHandler}>
									<ReactSVG
										src={
											isMenuOpened
												? "/img/icons/icon-close-nocircle.svg"
												: "/img/icons/icon-menu.svg"
										}
										// src="/img/icons/icon-menu.svg"
										wrapper="span"
										className="icon"
									/>
								</div>

								{/* FLOATING MENU */}
								<CSSTransition
									in={isMenuOpened}
									timeout={300}
									classNames="slideAnimLong"
									unmountOnExit
								>
									<FloatingMenu
										isLoggedIn={loggedIn}
										usuario={usuario}
										isMobileResponsive={isMobileResponsive}
										openLogin={openLogin}
										authLogout={authLogout}
										renderLangMenu={renderLangMenu}
									/>
								</CSSTransition>
								{/* FLOATING MENU */}
							</MenuWrapper>
						</MenuCol>
					) : (
						<MenuCol>
							<MenuColSkeleton />
						</MenuCol>
					)}
				</HeaderContainer>
			</MenuBar>
		</HeaderWrapper>
	);
};

const mapStateToProps = (state) => {
	const { isMobileResponsive, bodyNoScroll, headerHide } = state.app.global;
	return {
		loggedIn: state.auth.loggedIn,
		authResolved: state.auth.resolved,
		usuario: state.auth.usuario,
		isMobileResponsive,
		bodyNoScroll,
		headerHide,
	};
};

const mapDispatchToProps = {
	appRedirect,
	appModal,
	authLogout,
	appSetNoScroll,
	appSetHeaderHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
